.box
	&.faq
		.list-square
			padding 0px
			li
				background none
				padding-left 0px
			p
				display none
			img
				margin-top 10px
				width 100%
			h3
				-webkit-border-radius 6px
				-moz-border-radius 6px
				border-radius 6px
				border 1px solid
				border-color #00adef
				font-size 1em
				padding 5px
				text-transform inherit
				position relative
				
				.arrow
					position absolute
					right 10px
					width 0
					height 0
					border-top 8px solid transparent
					border-left 8px solid #00adef
					border-bottom 8px solid transparent
				
					&Open
						position absolute
						right 10px
						width 0
						height 0
						border-top 8px solid #00adef
						border-left 8px solid transparent
						border-right 8px solid transparent
						top 9px