/*
* As cores do caramelo estão no arquivo colors.styl
*
*/

.caramel
	margin 0 auto 20px auto
	overflow hidden
	width 300px
	padding 0

	li
		width 49.3%
		color #fff
		float left
		height 115px
		overflow hidden
		list-style none inside
		margin-bottom 5px

		&.left
			float left

		&.right
			float right


		&.item_text
			padding 10px

	a
		color #fff


	h3
		font-size 1.1em
		line-height 1.2em
		letter-spacing 0
		margin-bottom 0
		border 0


	h5
		margin-top 0
		margin-bottom 3px
		font-size 0.7em
		line-height 0.9em

	img
		width 148px
		height 100%
		min-height 115px

