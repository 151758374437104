.tag-editoria-tvfolha

	.section-name
		color #00adef

	.section-name.ambiente
		color #090

	.section-name.ciencia,.section-name.cotidiano,.section-name.educacao
		color #600

	.section-name.comida
		color #ac1528

	.section-name.classificados
		color #ed743f

	.section-name.equilibrio_e_saude,.section-name.equilibrioesaude,.section-name.empreendedor_social,.section-name.f5,.section-name.folhateen,.section-name.folhinha,.section-name.guia,.section-name.na_cultura,.section-name.serafina,.section-name.tec,.section-name.treinamento,.section-name.tudo_sobre,.section-name.turismo,.section-name.videograficos, .section-name.tudosobre, .section-name.tvfolhaaovivo, .section-name.folha95
		color #00adef

	.section-name.esporte
		color #f90

	.section-name.ilustrada,.section-name.ilustrissima
		color #ad2e3e

	.section-name.mercado
		color #360

	.section-name.mundo,.section-name.poder
		color #036

	.section-name.opiniao
		color #9b0204

	.section-name.saopaulo
		color #c00

	.section-name.empreendedorsocial
		font-size 0.6em
		color #036

	.section-name.dialogos-transformadores
		font-size 0.6em
		color #036


/* =============================================================================
   News index
   ========================================================================== */
.news-index
	margin-top 20px
	position relative

	h2
		border-bottom 2px solid #e71c22 
		color #e71c22
		font 300 25px/27px "metaserifpro-bold", Georgia, serif
		margin 0 0 20px
		padding-bottom 6px
		text-transform lowercase

	.icon-rss
		position absolute
		right 0
		top 15px

	a
		color #333

	a.ads,.news.full .ads .section-name,.news.full .ads .time,.news.full .ads a
		color #e71c22

	.section-name
		font-weight 700


	.subheadline
		text-transform uppercase

	.news ol
		margin 10px 0 15px 10px

	.news ol li
		font-size 14px
		line-height 24px

	.date
		font-size 15px
		font-weight 700
		line-height 22px

	.time
		color #666
		font-size 14px
		font-weight 700
		margin-right 10px
		line-height 16px


/* =============================================================================
   V�deo �ndice
   ========================================================================== */
.video-index .videos
	padding-left 0
	margin-bottom 20px


	> li
		border-bottom 1px solid #eee
		margin-bottom 10px

	.beside
		float left
		margin-bottom 10px
		margin-right 10px
		width 70px
		height 70px

	.video-title
		font-size 0.8em
		line-height 1.2em
		font-family Georgia,serif
		letter-spacing -1px
		display block
		margin-bottom 5px

	.video-time
		color #666
		font-family Arial, sans-serif
		font-size 0.6em
		line-height 16px

		time
			float left

	.icon-clock
		float left
		margin-top 5px
		margin-right 5px

	.pagination
		border 0
		margin-bottom 20px
		padding-top 0