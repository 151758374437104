.graphic-caption, .articleGraphicCaption, .graphic-credit,.articleGraphicCredit
	font-family helvetica,arial,sans-serif

.graphic-credit,.articleGraphicCredit
	font-size 0.7em
	text-align left
	line-height 12px
	padding-bottom 3px

.graphic-caption p, .articleGraphicCaption
	line-height 18px
	border 2px solid #dadada
	border-top none
	padding 5px 10px
	box-shadow inset 0 3px 1px -1px #e5e5e5

.graphic, .articleGallery,.articleGraphic
	margin-bottom 10px
	
	img
		display block
		width 100%
		height auto

	.articleGraphicImage
		width 100%

	&.articleGraphicBig,&.articleGraphicImage
		.graphic-image
			width 100%

	.graphic-caption, .articleGraphicCaption
		p
			margin 0
			font-size 1em

.articleGallery
	
	a
		position relative
		display block
		
		&:after
			content ""
			display block
			width 52px
			height 44px
			position absolute
			left 10px
			bottom 20px
			background-position -8px -162px

.video-article
	background #000
	height 0
	margin-bottom .8em
	overflow hidden
	padding-bottom 56.25%
	position relative

	.video-uolmais
		left 0
		height 100%
		position absolute
		top 0
		width 100%

	i
		width 96px
		height 94px
		display block
		margin -48px auto 0
		position absolute
		top 50%
		bottom 0
		left 0
		right 0
		z-index 1
		background-position -162px -101px

	.video-player
		width 100%
		display block
		margin auto

.folha-graphics
	width 96%
	height 100%
	margin 0 auto
	padding 1% 0

	.keep-ratio
		position relative
		padding-top 56%

		iframe
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			border none

.articleGraphic
	.box-image-differ
		position relative
		overflow hidden
		
	.box-image-differ-ui
		position absolute
		left 0
		top 0
		height 100%
		width 50%
		border-right solid 1px #000
		
	.box-image-differ-ui
		.box
			position absolute
			left 0
			top 0
			width 100%
			height 100%
			overflow hidden
			
		.image-differ
			height 100%
			background-repeat no-repeat
			background-size cover
			
	.box-image-differ
		.ui-resizable-resizing .ui-resizable-e 
			background-image none
	
.modal-image-container
	.image 
		overflow-x hidden
	
.modal-image-slider
	.ui-resizable
		position relative
		
	.ui-resizable-handle
		position absolute
		display block
		
	.ui-resizable-e
		height 100%
		width 154px
		right -77px
		top 0
		background url(//f.i.uol.com.br/folha/furniture/5.4/images/bt-arraste-154x28.png) no-repeat center
	
	.box-image-differ
		position relative
		overflow hidden
		margin 0 auto
		max-width 100%
	
	.box-image-differ-ui
		position absolute
		left 0
		top 0
		width 50%
		border-right solid 1px #000
	
	.box-image-differ-ui .box
		position absolute
		left 0
		top 0
		width 100%
		height 100%
		overflow hidden
	
	.box-image-differ .ui-resizable-resizing .ui-resizable-e
		background-image none
	
	.box-image-differ img 
		width auto
