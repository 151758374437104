/* Aeroportos */
.airports
	margin-top 2rem
	width 100%

	.clearfix
		clear both
		overflow auto
		zoom 1

	.section-head h1 a
		color #600

	&>header
		margin-bottom 1.2rem

		h2
			font-size 2.3em

	/* Form */
	.cities
		border-bottom 1px solid #ddd
		margin-bottom 1.2rem
		padding-bottom 1.2rem

		label
			background #f8f8f8
			color #777
			display block
			padding 1rem .8rem

			span
				color #666
				font-size 1.2rem
				font-weight 700
				line-height 1.6rem


		#airports-cities
			background #fff
			border 1px solid #666
			border-radius 4px
			color #444
			font-weight 700
			padding .4rem
			display block
			margin 0

			@media only screen and (max-width 767px)
				width 100%
			@media only screen and (min-width 768px)
				display inline
				margin-left 4%

	/* Situation */
	.situation
		width 100%

		h3
			font-size 1.8em

		.info
			>span
				display block
				font-size .95rem
				font-weight 700
			.date
				font-weight 100
		.about
			color #666
			font-size .95rem
			margin .4rem 0

	/* Content */
	.content
		width 100%

		.situation-nav
			height 35px
			padding 0
			position relative
			@media only screen and (min-width 768px)
				padding 0 0 0 20px

			li
				border solid 2px #eee
				padding 0
				border-bottom 0
				border-radius 3px
				display block
				list-style none
				float left
				margin 0 5px 0 0
				text-align left
				z-index 0
				font-size .6em
				width 31%
				@media only screen and (min-width 768px)
					text-align center
					font-size .9em

				&:last-child
					margin 0

			span
				color #999
				cursor pointer
				display inline-block
				font-weight 700
				height 33px
				line-height 28px
				outline none
				padding .2rem .3rem

			.active
				background #fff
				border 2px solid #aaa
				border-bottom 0
				z-index 10

				span
					background #fff
					color #333
					height 35px

	.container
		border solid 2px #aaa
		border-bottom-right-radius 3px
		border-bottom-left-radius 3px
		/*min-height 375px*/
		margin-bottom 1rem
		position static
		width 100%


		.info, .flights-in, .flights-out
			margin .7rem


		/* Status */
		.airport-status
			background #999
			color #fff
			font-size .95rem
			padding .6rem .6rem .4rem
			font-weight bold

		.status
			background #ccc
			border-radius 50%
			color #333
			float left
			font-size 14px
			line-height 16px
			height 16px
			margin-right 10px
			text-align center
			width 16px

		.open
			background #008c00

		.close
			background #c00

		.restriction, .no_information
			background #fff100


		table
			border-collapse collapse
			border-spacing 0
			margin-top 1.3rem
			width 100%

		caption
			font-size 1rem
			font-weight 700
			padding-bottom .3rem
			text-align left


		tbody
			td
				border-bottom 1px solid #999
				color #555
				font-size .85rem
				line-height 1rem
				padding .3rem 0


			.last td
				border-bottom 0


		/* Weather */
		.weather-info
			padding 10px

			caption
				background #999
				color #fff
				font-size .95rem
				padding .6rem .6rem .4rem

			td
				background #efefef
				border-bottom 1px solid #999
				border-right .5rem solid #efefef

				&:first-child
					border-left .5rem solid #efefef
					border-right 0
					width 30%

			tr
				&:first-child td 
				border-top .5rem solid #efefef


				&.last td
					border-bottom .5rem solid #efefef


		table.info-flight tbody td:first-child
			font-weight 700
			width 30%


		/* Flights */
		.flights-in .weather-info, .flights-out .weather-info
			margin 5rem 0


		.flight
			border 1px solid #eee
			border-radius .4rem
			box-sizing border-box
			float left
			margin-bottom 1rem
			padding 1rem .5rem
			width 100%

			.company, ul
				float left

			.company
				width 100%

				img
					max-height 100%
					max-width 100%

			ul
				margin-left 5%
				width 39%
				padding-left: 0;

				li
					list-style none
					font-size .80rem


					.fl-status
						color #00adef
						font-weight 700
						text-shadow 0 1px 1px rgba(0, 0, 0, .17)

					.cancelado
						color #ef0000

					.confirmado
						color #13b32d

					.ultima_chamada, .voo_encerrado
						color #ef9100

				&.flight-date
					margin 0 0 .5rem 0

				@media only screen and (min-width 768px)
					&.flight-status
						margin-left .5rem

	/* Source */
	.source
		font-size .75rem

		p
			margin 0

	.hide
		display none
		visibility hidden