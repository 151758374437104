*
	-webkit-tap-highlight-color transparent
	-webkit-hyphens none
	//-ms-box-sizing border-box
	box-sizing border-box

	&:after, &:before
		-webkit-hyphens none
		//-ms-box-sizing border-box
		box-sizing border-box

.vscroll #main,.vscroll #site-container,.vscroll body,html.vscroll
	height auto

.vscroll #pagination
	height inherit

body.noscroll #pagination li
	overflow hidden
html
	height 100%
body
	font-family Helvetica,Arial,sans-serif
	font-weight 100
	line-height 1.3em
	height 100%
	color #222

#site-container
	height 100%

#app-container
	position relative

#main
	margin 0 8px
	height 100%
	overflow hidden
	
	footer 
		border-top 0 none

		.copyright 
			border-top 0 none
			font-size 13px
			text-transform uppercase
			margin-top 10px
			font-family Georgia,serif
			padding-top 2px
			line-height 2em
			overflow hidden
			width 98%

			p 
				margin 0
				text-transform uppercase
#pagination
	float left
	margin 0
	padding 0
	width 100%
	
	&>li 
		margin 0
		padding 0
		list-style none inside
		display inline-block
		width 100%
		overflow hidden
		height auto
		-webkit-overflow-scrolling touch

			p 
				white-space normal

			.hide_next 
				margin-right 1024px

			.row 
				margin-bottom 0

	 .build-site,.build-site-add
		left 0
		position static
		height auto

.content-main

	overflow hidden
	position relative
	//float left
	width 100%
	//margin-top 110px
	position relative

	ul
		list-style none

		li.family-view
			float left

#head
	margin-bottom 10px
	margin-top 10px
	overflow hidden
	text-align center

	.top
		margin 0 auto
		overflow hidden
		
#today_date
	font-family helvetica,arial,sans-serif
	font-size 12px
	font-weight 700
	color #666
	text-transform uppercase
	line-height 15px
	margin-bottom 5px
	border-bottom 3px solid #FF0000
	text-align center
	padding-top 0px

	span
		display block

	time
		display inline-block
		margin-bottom 5px
		display none
		
#special_date
	margin-top 5px
	position relative

	&.date-15-11
		background url(//f.i.uol.com.br/app/1/topo-bandeira-tablet.png) repeat center bottom
		height 50px
		
#lastnews
	padding 0 10px
	margin 0
	border-bottom 1px solid #ccc
	font-size 14px
	height 35px
	overflow hidden
	width 100%
	text-align left
	line-height 34px
	display none

	.red
		padding-right 10px
		color #FF0000

	.blast
		display inline-block
		opacity 0
	a
		color #222

.fsp_newspaper,.cover_fsp_inside .cover,.follow_folha
	display none
	
.counter 
	margin 0 auto
	padding 0
	clear both
	display none


	li 
		list-style none inside
		padding 0
		margin 0 3px
		display inline-block


	.ball 
		height 0
		width 0
		background #ccc
		border-radius 10px
		padding 4px


		&.on 
			background #00aeef


	.counter .ball.one,.capa2 .counter .ball.two 
		background #00aeef

footer
	border-top 2px solid #ccc
	text-align center
	font-weight 700
	font-size 12px
	margin 0 10px
	padding-top 2px

.load_message
	h2
		font-size 18px
		text-align center
		margin 10px auto 0
		font-family Helvetica, Arial, sans-serif
		font-weight 100
		height 35px

.notablet
	display none

.visible
	display block!important

.clearfix
	clear both
	overflow auto
	zoom 1 

.hide
	display none
	visibility hidden

.btn
	background #fff
	border-radius 4px
	border 1px solid #ccc
	box-shadow inset rgba(0,0,0,0.07) 0 3px 1px, rgba(0,0,0,0.05) 0 2px
	color #666 !important
	cursor pointer
	display inline-block
	font-size 0.750em
	font-weight 700
	line-height 1.4
	padding 5px 8px 3px
	transition all .3s ease
	&:hover
		box-shadow inset rgba(0,0,0,0.06) 0 -8px 2px, rgba(0,0,0,0.09) 1px 2px 2px 
		text-decoration none

.sprite.icon-search
	background-position -90px -174px
	display inline-block
	height 18px
	width 18px