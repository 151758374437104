.search
.top-search
	.form
		fieldset
			border 0
			margin 0
			padding 0
	 
		label
			display inline-block
			margin-bottom 5px	 
			line-height 23px
	 
		label
		input
		button
		select
		textarea
			font-size 0.875em //14px
			font-weight normal
	 
		input
		button
		select
		textarea
			border-radius 4px
			color #555
			display inline-block
			font-family "Helvetica Neue", Helvetica, Arial, sans-serif
			height 20px
			line-height 20px
			margin-bottom 10px
			padding 4px 6px
			vertical-align middle
	 
		input
		textarea
			background-color #fff
			border 1px solid #ccc
			box-shadow inset 0 1px 1px rgba(0, 0, 0, 0.075) 
			transition border linear .2s, box-shadow linear .2s
		
		.btn-group
			display inline-block
		
		.control-group
			margin-bottom 10px
		
		.btn
			background #00b7f0
			box-shadow inset rgba(0,0,10,0.07) 0 3px 1px, rgba(0,0,10,0.05) 0 2px
			color #fff !important
			font-size 0.875em
			font-weight 700
			padding 8px 50px 6px
			&:hover
				box-shadow inset rgba(0,0,10,0.06) 0 -8px 2px, rgba(0,0,10,0.09) 1px 2px 2px


.search
	.form
		#qLocal
			width 95%
		
		.sd
		.ed
			font-weight 700
		
		.search-date
			height 30px
			width 99%
		
		.btn-control
			padding-top 10px
			text-align center
			
			.btn
				font-size 1em
				height 35px
	
	.section-head
		h1
			color #900
	
	.tip-search
		display block
		font 300 1em/1.2 Georgia, Times, serif 
		margin 20px 0
	
	.message.info
		font 300 1em/1.2 Georgia, Times, serif 
		min-height 200px
		
		 h2
			border-bottom 2px solid #333
			padding-bottom 5px
		
		p
			font 1em/1.2 Georgia,serif
	
	.search-title
		border-bottom 2px solid #00b7f0
		color #00b7f0
		padding-bottom 8px
	
	.search-form
		background #f3f3f3
		border 1px solid #ddd
		clear both
		border-radius 4px
		box-shadow rgba(0,0,0,0.05) 2px 3px
		float none
		margin 14px auto
		padding 10px 14px
		max-width 400px
		
		.control-label
			font-weight 700
		
		.controls-row
			label
				margin 2px 2px
				vertical-align bottom
			
			input
				margin 1px 0 0
	
	.search-results-list
		margin 0
		padding 0
		
		li
			border-top 1px dotted #ccc
			font 300 1em/1.2 Arial, Helvetica, sans-serif 
			list-style none outside
			padding 14px 5px 14px
			overflow hidden
			transition all .3s ease-in
			&:first-child
				border-top none
			&:hover
				background #f5f5f5
				box-shadow inset rgba(255,255,255,.3) 0 10px 2px
			
			a
				text-decoration none
				&:hover
					.url
						text-decoration underline
		
		.search-results-title
			color #444
			font-size 1.063em
			line-height 1.2
			margin 0
			
			a
				color #444
			
			.news-date
				color #777
				display block
				font-family Helvetica, Arial, sans-serif
			
			.news-title
				font-size 24px
				font-weight 100
			
		.url
			color #00aeef
			display block
			font-size 0.813em
			display inline
		
		.search-time
			color #808080
			display inline
			font-size small
		
		.content
			display block
			font 300 1em/1.2 Georgia, Times, serif 
			margin 5px 0 12px 0
			
			p
				color #444
				display inline
				font-size 0.938em
				margin 10px 0
				
				> span
					display inline
		
		.search-img-thumb
			float left
			margin-right 10px
		
		.search-img-thumb-legend
			clear both
			float left
			text-align center
			width 100px
			color #808080
			font-size 0.750em
		
		.search-subresults
			margin-left 22px
			width 535px
			
			li
				float left
				border 0
				width 255px
				margin 0
				padding 4px
	
	.search-pagination
		border 1px solid #e9e9e9
		border-radius 2px
		box-shadow inset rgba(0,0,0,0.035) 2px 2px
		font-size 0.875em
		margin 20px 0
		padding 7px 5px
		text-align center
		
		a
			border-radius 5px
			color #444
			display inline-block
			margin 0 4px 4px
			padding 4px 8px
			transition .2s ease-in
		
		b
			margin 0 4px 4px
			padding 5px 8px

.top-search
	background #fff
	margin 0 auto
	padding 10px 0
	
	fieldset
		border 0
		margin 0
		padding: 0 10px
		
		#query
			border 1px solid #ccc
			border-radius 0
			border-bottom-left-radius 2px
			border-top-left-radius 2px
			box-shadow inset rgba(0,0,0,0.07) 0 3px 1px 
			color #666
			float left
			font-size 0.875em
			min-height 21px
			line-height 1.5
			padding 3px 10px 0 35px
			transition all .3s ease-in
			width 75%
			
			&:hover
				border-color #999
	
	label
		display block
		margin-bottom 5px

	.icon-search
		background-position -90px -169px
		border-right 1px solid #ccc
		cursor text
		display block
		height 25px
		margin 0 5px
		padding-right 23px
		position absolute
		text-indent -9999px
		z-index 1
		
	.top-search-btn
		background #ededed
		border 1px solid #ccc
		border-radius 0
		border-bottom-right-radius 2px
		border-top-right-radius 2px
		box-shadow inset rgba(0,0,0,0.07) 0 3px 1px
		color #666
		font-size 0.875em
		height 26px
		padding 3px 6px
		width 25%