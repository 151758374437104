.icon-clock, .meta .date i
	width 14px
	height 14px
	display inline-block
	margin 0 7px
	background #FFF
	border 1px solid #999
	position relative
	border-radius 11px

	&:before, &:after
		content ''
		width 5px
		height 2px
		background #999
		position absolute

	&:before
		right 5px
		top 4px
		transform rotate(62deg)

	&:after
		right 2px
		top 6px

.content-type-news
	font-family Georgia,serif

	p
		line-height 125%
		margin-bottom 20px
		font-size 1.2em
		&.talktous
			font-size 0.96em

	.tagline
		font-size .96em

	.star
		background url(//f.i.uol.com.br/app/2/star.gif) no-repeat center
		height 30px
		overflow hidden
		text-align center
		text-indent -9999px

	.meta
		color #777
		.credits
			color #777
			text-transform uppercase

			p
				margin 0
		.date
			color #999
			text-transform lowercase
			font-weight 400
			line-height 23px
			&.new-date
				text-transform capitalize

		.credits p, .date
			font-size 0.9em
			font-family Helvetica,Arial,sans-serif

		h1
			font-size 2.5em
			color #222

		h5
			text-transform uppercase
			font-family Helvetica,Arial,sans-serif
			font-size 1.1em

			p
				margin 0
				font-size inherit

	article
		a
			color #00adef
		
		p.division
			background #fff url(//f.i.uol.com.br/x-cccccc.gif) repeat-x center
			color #fff
			margin 0
			text-align right

		ul
			padding 0
			font-size 1.3em

			li
				margin-bottom .5rem
				
		table
			
			&.specialContainer
				border-bottom 2px solid #00adef
				float right
				margin 0 0 10px 0
				width 100%
			
				th
					border-bottom 1px solid #00adef
					border-top 2px solid #00adef
					font-size 16px
					padding 4px 0
					text-align left
					text-transform uppercase

				tr 
					td
						font-family Georgia, serif
						font-size 1em
						line-height 1.2em
						padding 5px 0

						&.columnist
							text-transform uppercase
							img
								width 100%

						&.link
							display table-cell
							border none
							font-weight normal
							font-size .9em
							line-height 1.25em
							a
								color #000
								margin-left 10px
		
						&.bullet
							background url('//f.i.uol.com.br/app/2/bg-square.png?2015101401') no-repeat scroll left 12px rgba(0, 0, 0, 0)
							height 5px
							width 5px
    
    .talktous, .tagline
		font-family helvetica,serif
		font-weight 700
		font-size 1em
	
	.talktous
		a 
			color #00adef

	.article-end
		padding-top 30px
		z-index 1
		overflow hidden
		clear both
		background url(//f.i.uol.com.br/app/2/i-stars.png) top center no-repeat

	#share-menu
		padding 0

	.to-erramos
		margin-left 10px
		text-transform none
		color #000
		font-style italic

		.exclamation-icon
			display inline-block
			width 14px
			height 13px
			background url(//f.i.uol.com.br/app/2/icn-exclamacao.svg) 0 1px no-repeat
			background-size contain

	.aside-erramos
		font-family helvetica,serif
		font-size 0.8em
		margin-bottom 25px
		margin-top -20px

		.erramos:before
			content ''
			background #fff url(//f.i.uol.com.br/app/2/icn-exclamacao.svg) 0 3px no-repeat
			display block
			left 0
			position absolute
			width 14px
			height 18px
			background-size contain

		.erramos
			margin 0 0 15px 0 
			border-bottom solid 2px #ed1c24
			position relative
			color #ed1c24
			text-transform uppercase
			font-weight 700
			padding-left 18px

		li
			background url(//f.i.uol.com.br/folha/furniture/5.4/images/bg-square.png?2013082801) no-repeat 0 8px
			margin-bottom 3px
			padding-left 10px

			time
				font-size .9em
				color #999
				font-style italic
				margin-right 5px

				.icon-clock
					margin 0 3px

	.teaser-warning
		text-align center
		width 100%
		position relative
		padding 7px 0 0
		z-index 555
		font 100 20px/27px "folhaii-heavy" , serif
		color #000
		margin-bottom 30px

		&:before
			content ''
			width 100%
			height 150px
			display block
			position absolute
			z-index 555
			margin-top -150px
			background linear-gradient(to top, rgba(255,255,255,1) 0%, transparent 100%)
		a
			color #25b8f4
			text-decoration underline

		.teaser-hint_header
			padding 15px 30px 0
			display block
			border-top-left-radius 5px
			border-top-right-radius 5px
			background-color #ececec
			.three-star
				background url(//f.i.uol.com.br/folha/furniture/5.4/images/icn-three_stars.png) no-repeat center
				padding 0 15px 0 0
				height 17px
				display block
				width 100%
				padding 0
				margin-bottom 10px

		.teaser-hint_footer
			display block
			background-color #dddddd
			padding 20px 30px
			border-bottom-left-radius 5px
			border-bottom-right-radius 5px

		.teaser-hint_options
			padding 12px 0
			background-color #ececec
			font bold 14px Helvetica, Arial, Sans-Serif
			li
				padding 10px 0
				background none
				display block
				margin 0 15px
				border-bottom 1px solid #c4c4c4
			li:last-child
				border-bottom none

	.event-rating.img-label-rating
		padding-bottom 100px
		background url(//f.i.uol.com.br/folha/ilustrada/images/trj-legenda-criterios-avaliacao-320x86.png) bottom left no-repeat ;
		
		&-new
			padding-bottom 113px
			background url(//f.i.uol.com.br/folha/ilustrada/images/trj-legenda-criterios-avaliacao-305x105.png) bottom left no-repeat ;	

#bt-read-more-content
	display block
	text-align center
	font-family helvetica, sans-serif
	font-weight 700
	border-top solid 2px #00adef
	margin 25px 0 35px 0
	padding 12px 0
	clear both

	&:after
		content ''
		display inline-block
		width 8px
		height 12px
		margin-left 5px
		background-position -92px -365px
		transform rotateZ(90deg)

.eventTickets
	border 1px solid #ccc
	margin-bottom 20px
	width 100%

	.logoIngressos
		padding 10px 10px 10px 12px
		width 100px

	.logoIngressos img
		float left
		width 100%
		height auto

	.description
		display none

	.buttonBuy
		padding 12px 12px 0 0px
		width 170px

		a
			padding 13px 20px
			-webkit-border-radius 4px
			-moz-border-radius 4px
			border-radius 4px
			border 0
			color #fff
			background #a0d468
			position relative
			box-shadow 0 3px #81b449
			font 1em unitot-boldregular,Arial,Helvetica
			display inline-block
			float right
			top -8px
			left 4px