.roads-situation

	&>h2
		font-size 2em

	.road-update
		color #777


	.none i, .free i, .moderate i, .slow i, .noinfo i
		background #ccc
		border-radius 50%
		float left
		height 20px
		margin-right 10px
		width 20px

	.free i
		background #339800

	.moderate i
		background #f3ea01

	.slow i
		background #f00

	.noinfo i
		background #aaa


	/* Status */
	.status
		background #f8f8f8
		color #777
		margin .8rem 0
		padding .7rem .8rem

		.item
			font-size 1rem
			line-height 22px
			margin 0 1.8rem .5rem 0 
			text-transform uppercase

			@media only screen and (min-width 758px)
				float left

	/* Roads */
	.roads
		border-top 1px solid #eee
		font-size 1.1rem
		list-style none outside none
		margin .7rem .8rem 0
		padding 1rem 0 0

		li
			color #777
			font-size .8rem
			padding-top .8rem

			span
				display block
				color #222
				font-size 1.2rem
				font-weight 700

			.description
				font-size 1rem
				margin .3rem 30px .9rem


	.hide
		display none
		visibility hidden