/*=============================
=            Mixins            =
=============================*/
size($width, $height)
    width $width
    height $height 

sprite($width, $height, $position)
    width $width
    height $height 
    background-position $position


/*=================================
=            Hide text            =
=================================*/
.hide-text
    font 0/0 a !important
    color transparent !important
    text-shadow none !important


.comments-article > article > header .to-report,
.comments-article .rating .comment-like,
.comments-article .rating .comment-dislike 
    @extend .hide-text


/*============================
=            Hide            =
============================*/
.comments-article .hide,
.comments-article .share
    display none !important
    visibility hidden


/*================================
=            Clearfix            =
================================*/
.comments-article > article > header:before,
.comments-article > article > header:after,
.comments-article footer:before,
.comments-article footer:after
    content " "
    display table


.comments-article > article > header:after,
.comments-article footer:after
    clear both


/*==============================
=            Sprite            =
==============================*/
.comments-article > article > header h3 a::before,
.comments-article header .more:after,
.comments-article .comment-action:after,
.comments-article .section-sprite,
.comments-article .sprite
    display inline-block
    vertical-align middle
    background-repeat no-repeat

.comments-article .warning
    sprite(18px, 16px, -204px -321px)

.comments-article .comment-like,
.comments-article .comment-dislike
    size(18px, 17px)

.comments-article .comment-like
    background-position -128px -320px

.comments-article .comment-dislike
    background-position -166px -322px

.comments-article > article > header h3 a:before,
.comments-article .support
    sprite(16px, 16px, -54px -321px)
    margin-right 8px

.comments-article .section-sprite.reply
    sprite(18px, 15px, -89px -322px)
    margin-right 10px

.comments-article .comment-large
    sprite(20px, 20px, -14px -318px)
    float left
    margin-right 10px
    

/*==========================================
=            Line first article            =
==========================================*/
.comments-article article
    border-bottom 1px dotted #ccc
    
.comments-article article:first-of-type
    border-top 1px dotted #ccc
    margin-top 16px

.comments-article article:not(:last-of-type) .terms
    display none


/*======================================
=            Header Section            =
======================================*/
/**
 *
 * Title
 *
 */
.comments-article header h2
    color #00adef
    font 600 24px "MetaSerifPro-Bold", Georgia, serif
    margin-bottom 0
    padding-bottom 10px
    text-transform lowercase
    border-bottom 2px solid #00adef

/**
 *
 * Ver todos os comentários
 *
 */
.comments-article header .more
    display block
    color #00adef
    font 700 16px/50px Arial, Georgia, serif
    border-bottom 1px dotted #ccc 

/**
 *
 * User info
 *
 */
.comments-article .user-info
    margin-bottom 8px

.comments-article .user-info a
    color #666
    font italic 14px "Arial-BoldItalicMT", Arial, Helvetica, sans-serif

/**
 *
 * Comentar esta reportagem
 *
 */
.comments-article .comment-action
    display inline-block
    margin 16px 0
    font 700 16px Arial, Georgia, serif
    color #00adef

/**
 *
 * Chevron
 *
 */
.comments-article header .more:after,
.comments-article .comment-action:after
    content ""
    size(8px, 12px)
    margin 0 10px
    background-position -92px -365px

/**
 *
 * Mensagem
 *
 */
.comments-article .btn-comment-default {
    background-color: #00adef;
    border-radius: 5px;
    color: #fff;
    display: block;
    margin:0 24px 24px 0;
    padding: 6px 8px;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

/*======================================
=            Header Article            =
======================================*/
.comments-article > article > header
   padding 16px 0 10px 0

.comments-article > article > header h3
    display inline-block

.comments-article > article > header h3 a
    font 800 italic 14px/22px Arial, Helvetica, sans-serif
    color #666
    letter-spacing 0

.comments-article > article > header h3 a:before
    content ""
    margin 0 10px

.comments-article > article > header .time
    font italic 14px Arial, Helvetica, sans-serif
    color #666
    display inline-block
    padding-bottom 10px


/*==============================================
=            Style Buttons comments            =
==============================================*/
.comments-article > article > header .to-report,
.rating .vote,
.comments-article footer .terms-conditions a,
.comments-article header .terms-conditions a
    min-height 42px
    border 1px solid #d3d3d3
    border-radius 3px
    box-shadow 1px 2px 2px #f0f0f0
    float right
    list-style none outside
    font 700 12px "Arial-BoldItalicMT", Arial, Helvetica, sans-serif
    color #666
    margin 0 2px
    padding 10px 7px
    transition background-color .2s ease-in
    vertical-align middle

.comments-article header .terms-conditions a
    min-height 38px

/*====================================
=            Comment body            =
====================================*/
.comments-article .comment-body
    margin 10px 0 0 0
    clear both

.comments-article .comment-body p
    color #000
    font 16px Arial, Georgia, serif
    margin 0
    overflow-x hidden


/*=======================================
=            Popup / Confirm            =
=======================================*/
.comments-article .confirm-rating::after,
.comments-article .confirm-rating::before
    content ""
    position absolute
    top -20px
    left 50%
    z-index 1
    transform translateX(-50%)
    border-left 20px solid transparent
    border-right 20px solid transparent
    border-bottom 20px solid #fff

.comments-article .confirm-rating::after
    top -21px
    z-index 0
    border-bottom 20px solid #cacaca

.comments-article .confirm-rating
    position absolute
    background-color #fff
    border 1px solid #cacaca
    font 700 11px/130% Arial, sans-serif
    margin 70px 0 0 -60px
    padding 10px
    width 130px

.comments-article .confirm-rating.good
    right 10px

.comments-article .confirm-rating.bad
    right 20px

.comments-article .confirm-rating h4
    color #016fcd
    font 700 11px/130% Arial, sans-serif
    letter-spacing normal

.comments-article .confirm-rating.bad > h4
    color #ed1c24

.comments-article .confirm-rating .action
    text-align center

.comments-article .confirm-rating .action a
    background-color #e6e7e8
    color #404040
    display block
    font-size 14px
    padding 8px 0
    margin 10px 0
    text-align center
    text-decoration none
    box-shadow none
    border none
    border-radius 0

.comments-article .confirm-rating .unstyled
    margin-top 10px

.comments-article .confirm-rating .unstyled li
    margin-bottom 0

.comments-article .confirm-rating .unstyled a
    font 700 11px/130% Arial, sans-serif
    color #006


/*======================================
=            Footer Article            =
======================================*/
.comments-article footer .terms,
.comments-article header .terms
    text-align right
    margin 15px 0

.comments-article footer small
    font normal 12px Arial, Georgia, serif
    color #777


/**
 *
 * Button Reply
 *
 */
.comments-article > article > footer .reply.btn
    width 120px
    font 700 12px "Arial-BoldItalicMT", Arial, Helvetica, sans-serif
    padding 0
    margin 20px 0
    color #666
    float right
    font-family Arial, Georgia, serif
    border none
    box-shadow none

/**
 *
 * Button terms conditions
 *
 */

.comments-article footer .terms-conditions,
.comments-article header .terms-conditions
    display inline-block
    float right
    margin-top 5px

.comments-article footer .terms-conditions a,
.comments-article header .terms-conditions a
    font-size 14px
    padding 10px

/*======================================
=            Footer Section            =
======================================*/
.comments-article > footer
    margin 15px 0 5px 0
