.headline,.three-headline-top
	border-bottom 1px solid #ccc
	padding-bottom 10px
	margin-bottom 10px
	color #666

	/.headline, &.curinga-header > div
		//clearfix para imagens flutuadas
		clear both
		overflow auto
		zoom 1 

	h2, /.three-headline-top h2
		font-size 2.2em
		margin-top .5rem

		a
			line-height 2.3rem
	
	/.three-headline-top h2
		font-size 3.2em
	h3, & a
		line-height 28px

	h3
		font-size 1.6em

	h4
		font-family Georgia,serif
		font-size 1.5em
	h5
		font-size 0.9em
		margin 0 0 2px 0


	.section-title
		font-family 'MetaSerifPro-Bold',Georgia,serif
		text-transform lowercase
		font-size 2.5em
		border-bottom 2px solid
		padding-bottom 5px
		margin-bottom 0
	a
		line-height 20px
		margin-bottom 10px
		font-size 1em
		color #222

	&>a
		//font-size 1.62em
	img
		width 100%
		display block
		border 0
		margin 0 auto 5px auto

	.last
		border-bottom 0 none
		padding-bottom 0
	&last-child
		border-bottom 1px solid #ccc
		padding-bottom 20px

	&.space-ghost
		display none

	.intro
		display inline

	.lide
		padding-top 0px!important
		clear both
		
	&.double-headline
		overflow  visible
		h2
			font-size 2.2em
			margin-top .5rem

	.image_150x115,.image_35x35,.image_70x70
		float left
		margin 0 10px 10px 0

	.image_35x35
		height 35px
		width 35px

	.image_70x70
		height 70px
		width 70px

	.image_150x115
		height 115px
		width 150px
		
	.box-header
		color #00aeef
	
	&.curinga-header, .box-header
		border-bottom 0px
		clear both
		overflow auto
		zoom 1

		&>div
			border-bottom 1px solid #ccc
			margin-bottom 20px
			padding-bottom 5px

		.curinga_title > h5, &> h5, .box-title
			border-bottom 2px solid #00aeef
			padding-bottom 8px
			margin-bottom 15px
			text-transform lowercase
			margin-top 15px
			font-family "metaserifpro-bold", Georgia, serif
			font-weight 300
			font-size 1.6em

		[href='//m.folha.uol.com.br/fsp/'].curinga_title > h5
			font-size 1.3em
			
	&.curinga-header 
		margin-bottom 0
		.curinga_title h5.box-title
			margin-bottom 4px

	&.video-article, &.gallery-article
		background none
		text-align left

		.section-title
			padding-bottom 0px
			font-size 1.6em
			line-height 1.6em
			
	&.no-line
		border 0
		margin-bottom 0

.headline.audiencia
	img
		border-bottom 2px solid #00aeef
	.audiencia
		padding 0
		li
			font-size 0.7em
			strong
				color #020202
				text-transform uppercase
				font-size 0.8em
			span
				color #767676
				
.headline.patrocinado
	border-bottom none
	background #f2f2f2
	padding 0 10px
	h5
		border-bottom 2px solid #aa2425
		color #c61912
		padding-bottom 8px
		margin-bottom 15px
		text-transform lowercase
		margin-top 15px
		font-weight 300
		font-size 1.6em
		font-family Tahoma, Arial, Helvetica, sans-serif
	h3
		font-family Tahoma, Arial, Helvetica, sans-serif
		font-size 1.2em
	p
		font-family Tahoma, Arial, Helvetica, sans-serif
		margin 0 0 10px 0
		
.latest-news
	margin 20px 0 0
	.latest-news-list-item-content
		.latest-news-list-item-content-title
			margin-top 12px
		.latest-news-list-item-content-date
			color #999
			font-size .9em
			font-weight 400
		.latest-news-list-item-content-date 
			.icon-clock
				margin 2px 3px
				vertical-align top


.latest-news-title
	display block
	border-bottom 2px solid #e71c22
	color #e71c22
	font-family "metaserifpro-bold", Georgia, serif
	line-height 160%
	margin-bottom 15px
	text-transform lowercase