.smartbanner
  position absolute
  top 0
  left 0
  overflow-x hidden
  width 100%
  height 84px
  background #f3f3f3
  font-family Helvetica, sans, sans-serif

.smartbanner__exit 
  position absolute
  top calc(50% - 6px)
  left 9px
  display block
  margin 0
  width 12px
  height 12px
  border 0
  text-align center

.smartbanner__exit::before,
.smartbanner__exit::after 
  position absolute
  width 1px
  height 12px
  background #767676
  content ' '

.smartbanner__exit::before 
  -webkit-transform rotate(45deg)
  transform rotate(45deg)

.smartbanner__exit::after
  -webkit-transform rotate(-45deg)
  transform rotate(-45deg)

.smartbanner__icon
  position absolute
  top 10px
  left 30px
  width 64px
  height 64px
  border-radius 15px
  background-size 64px 64px

.smartbanner__info
  position absolute
  top 10px
  left 104px
  display -webkit-box
  display -ms-flexbox
  display flex
  overflow-y hidden
  width 60%
  height 64px
  -webkit-box-align center
  -ms-flex-align center
  align-items center

.smartbanner__info__title
  font-size 14px
  font-weight bold

.smartbanner__info__author,
.smartbanner__info__price
  font-size 12px
  font-weight 400

.smartbanner__button
  position absolute
  top 32px
  right 10px
  z-index 1
  display block
  padding 0 10px
  min-width 10%
  border-radius 5px
  background #f3f3f3
  color #1474fc
  font-size 18px
  text-align center
  text-decoration none

.smartbanner__button__label
  font-weight 300
  text-align center