.fsp
	.fac-simile
		color #00ADEF
		font 300 1.9em/.94 "metaserifpro-bold", Georgia, serif
		text-transform lowercase
		border-bottom 2px solid #00ADEF
		line-height 35px
		margin-bottom 20px

	.edition-btn
		display inline-block
		padding 0
		margin-bottom 20px
		width 100%

		li
			border 2px solid #00ADEF
			float left
			padding 8px 0
			text-align center
			width 50%
		
		.sel
			background-color #00ADEF

			a
				color #fff

		a
			color #00ADEF
			text-transform uppercase
			font 300 1em arial, sans-serif
			font-weight bold

		@media only screen and (max-width 380px)
			a
				font-size .8em

	.edition-cover
		display block

		img
			border 1px solid #dedede
			width 100%
			display block


	.fsp-sidebar

		&>h2
			border-bottom 2px solid #00adef
			color #00adef
			font 300 1.6em/1em "metaserifpro-bold", Georgia, serif
			margin 0 0 15px 0
			padding-bottom 6px
			text-transform lowercase

		.chamadas
			margin-bottom 20px

			h2
				font-size 1.45em
				margin-bottom 5px
				text-transform lowercase


			ul
				padding 0


				li
					margin-bottom 4px

			a
				color#666
				font-weight bold
				font-size 0.9em


	.box-assine
		display block
		max-width 300px
		margin 20px auto 0
		background-color #ccc
		padding 25px 15px
		background-color #ededed
		border-radius 3px
		text-align center


		a
			display block
			text-align center
			color #666
			font 300 1.3125em "metaserifpro-bold", Georgia, serif

			&.btn-assine
				display inline-block
				background-color #00b0f6
				color #fff
				font-size .9375em
				font-weight 700
				line-height 36px
				width 245px
				border-radius 3px
				box-shadow -3px 3px 6px 0px rgba(50, 50, 50, 0.45), inset -4px 5px 8px -4px rgba(50, 50, 50, 0.20)
				font-family Arial, Helvetica, sans-serif

		img
			max-width 250px
			height auto
			margin 30px 0

	.box-assine-ja
		background-color #ededed
		display block
		margin 30px auto
		padding 20px
		text-align center
		width 300px

		&:hover
			text-decoration none

		span
			display block

		.txt
			color #666
			font-size 18px

		.info
			color #00adef
			font-weight bold

		.detail
			color #999
			font-size 10px
			margin-top 5px
			text-transform uppercase

		.bt
			background-color #00b0f6
			border-radius 3px
			box-shadow -3px 3px 6px 0px rgba(50, 50, 50, 0.45), inset -4px 5px 8px -4px rgba(50, 50, 50, 0.20)
			color #fff
			display inline-block
			font-family Arial, Helvetica, sans-serif
			font-size 0.9em
			font-weight 700
			line-height 36px
			margin 20px auto
			text-transform uppercase
			width 140px

	//Calendar
	.box.calendario
		h2
			border-bottom 2px solid #00adef
			color #00adef
			font 300 1.5625em "metaserifpro-bold", Georgia, serif
			margin 30px 0 10px
			padding-bottom 6px
			text-transform lowercase

	#calendar
		margin 20px auto auto
		width 300px

	.calendario
		.txt
			font-size 14px

		.btn
			background-color #e6e6e6
			border none
			border-radius 0
			box-shadow none
			font-size 14px

		.show
			td
				text-align center
				font bold .875em arial, verdana, sans-serif
				text-transform uppercase
				border-right 1px solid #fff
				border-bottom 1px solid #fff

			.title
				padding-top 4px

			.cel
				width 42px
				padding 10px

			.menu
				background-color #e6e6e6

			.des
				background-color #e6e6e6
				color #999

			.sel
				background-color #333
				transition all .2s ease-in

				&:hover
					opacity 0.6

				a
					color #fff
				
				.today
					text-decoration underline

				.tsel
					color #666
					cursor default

	// �ndice
	.indice
		display inline-block
		margin 20px 0
		width 100%

		li
			a:visited
				color #551a8b
				span
					color #551a8b

		a
			line-height 25px

		span
			&.kicker
				color #000
				font-size 1.0833em

		.section-masthead
			border-bottom 2px solid #036
			margin-bottom 15px
			padding-bottom 4px

			.label
				font-family 'MetaSerifPro-Bold', Georgia, serif

			a
				color #036
				font-size 1.3em

		.topo
			float right
			font bold .75em Georgia, Times, serif
			color #666
			margin -4px 0 0 5px

			.seta
				border none
				margin 0

			&:after
				background url(//f.i.uol.com.br/folha/furniture/5.4/images/sprite.png?2015310301) no-repeat transparent
				background-position -286px -549px
				content ""
				float right
				height 10px
				margin 1px 10px 0 5px
				width 10px

		.serafina
			.section-masthead
				.label
					background-image url('//f.i.uol.com.br/folha/fsp/images/sprite-edicao_impressa.png?2012102203')
					background-position -18px -874px
					width 80px
					display block
					height 30px

		.sectionindex
			font-size 1.125em
			font-family 'Georgia', verdana, arial
			margin 0
			padding 0
			
			li
				margin-bottom 10px

			.sub
				margin-bottom 20px
				padding 0
				
				li
					margin 0

			a
				color #000

		.sbd
			list-style-type disc
			margin -10px 0 0 20px
			list-style-position inside
			color #000

		.kicker
			color #999
			font 300 1.22em "folhaii-heavy" , serif
			margin 0
			text-transform capitalize

			&.sub
				margin 0

		&.textos
			margin-bottom 0
			.section-masthead
				color #00adef
				border-bottom 2px solid #00adef
				margin-bottom 0

		&.opiniao
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.acontece
			.section-masthead
				border-bottom 2px solid #990033

			.section-masthead > a span
				color #990033

		&.poder
			.section-masthead
				border-bottom 2px solid #003366

			.section-masthead > a span
				color #003366

		&.mundo
			.section-masthead
				border-bottom 2px solid #003366

			.section-masthead > a span
				color #003366

		&.mercado
			.section-masthead
				border-bottom 2px solid #336600

			.section-masthead > a span
				color #336600

		&.cotidiano
			.section-masthead
				border-bottom 2px solid #660033

			.section-masthead > a span
				color #660033

		&.ciencia
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.saude
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.saudeciencia
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.cienciasaude
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.esporte
			.section-masthead
				border-bottom 2px solid #FF9900

			.section-masthead > a span
				color #FF9900

		&.ilustrada
			.section-masthead
				border-bottom 2px solid #990033

			.section-masthead > a span
				color #990033

		&.tec
			.section-masthead
				border-bottom 2px solid #00aceb

			.section-masthead > a span
				color #00aceb

		&.campinas
			.section-masthead
				border-bottom 2px solid #00aceb

			.section-masthead > a span
				color #00aceb

		&.equilibrio
			.section-masthead
				border-bottom 2px solid #00aceb

			.section-masthead > a span
				color #00aceb

		&.comida
			.section-masthead
				border-bottom 2px solid #725245

			.section-masthead > a span
				color #725245

		&.turismo
			.section-masthead
				border-bottom 2px solid #00aceb

			.section-masthead > a span
				color #00aceb

		&.ilustrissima
			.section-masthead
				border-bottom 2px solid #ad2e3e

			.section-masthead > a span
				color #ad2e3e

		&.quadrinhos
			.section-masthead
				border-bottom 2px solid #e2222d

			.section-masthead > a span
				color #e2222d

		&.corrida
			.section-masthead
				border-bottom 2px solid #849ca2

			.section-masthead > a span
				color #849ca2

		&.ribeirao
			.section-masthead
				border-bottom 2px solid #42bef0

			.section-masthead > a span
				color #42bef0

		&.folhinha
			.section-masthead
				border-bottom 2px solid #003366

			.section-masthead > a span
				color #003366

		&.folhateen
			.section-masthead
				border-bottom 2px solid #003366

			.section-masthead > a span
				color #003366

		&.saopaulo
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.especial
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.especial2
			.section-masthead
				border-bottom 2px solid #990000

			.section-masthead > a span
				color #990000

		&.serafina
			.section-masthead
				border-bottom 2px solid #000

			.section-masthead > a span
				color transparent

		&.newyorktimes
			.section-masthead
				border-bottom 2px solid #000

			.section-masthead > a span
				color transparent

		&.classificados
			.section-masthead
				border-bottom 2px solid #f69431

			.section-masthead > a span
				color #f69431

		&.imoveis
			.section-masthead
				border-bottom 2px solid #ed424f

			.section-masthead > a span
				color #ed424f

		&.veiculos
			.section-masthead
				border-bottom 2px solid #88aaba

			.section-masthead > a span
				color #88aaba

		&.negocios
			.section-masthead
				border-bottom 2px solid #1db6bc

			.section-masthead > a span
				color #1db6bc

		&.carreiraseempregos
			.section-masthead
				border-bottom 2px solid #1db6bc

			.section-masthead > a span
				color #1db6bc

		&.empregosecarreiras
			.section-masthead
				border-bottom 2px solid #1db6bc

			.section-masthead > a span
				color #1db6bc

		&.ombudsman
			.section-masthead
				border-bottom 2px solid #f00

			.section-masthead > a span
				color #f00


// Selo Edi��o impressa
.article-stamp
	.versao-impressa
		background-color #00adef
		color #fff
		display block
		margin 0 0 30px
		padding 6px 0
		text-align center