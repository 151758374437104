/*
 * Live Blog
 *
 * @author	Anderson Felix - (a.felix) <anderson.felix@grupofolha.com.br>
 * @since	2016
 */

.live-blog-subtitle
	color #00aeef
	display block
	font-family Helvetica,Arial,sans-serif
	font-size 1.1em
	font-weight 700
	margin-bottom 3px
	text-transform uppercase

.live-blog-category
	border-bottom 2px solid #00aeef
	color #00aeef
	padding-bottom 8px
	margin-bottom 15px
	text-transform lowercase
	margin-top 15px
	font-family metaserifpro-bold, Georgia, serif
	font-weight 300
	font-size 1.6em

.live-blog-header
	margin-bottom 13px

.live-blog-news
	a
		color #00aeef

// Posts
.live-blog-posts
	margin-top 2.5em

// Post
.live-blog-post
	border-bottom 1px dashed #ddd
	padding-bottom 1em
	padding-top 1em

	.live-blog-post-period
		//float left
		//text-align center
		//width 20%
		display block
		
		.post-wrap-time
			margin-bottom 5px
			
			.info-game-time,
			.info-update_message
				display inline-block
				font-family Georgia, serif
				color #00adef
				font-style normal
				
				.game_time
					font-size 1.35em
					
				.game_period
					font-size .85em
				
			.info-update_message
				color #666
				font-size .85em
					
			.post-time
				display inline-block

				time
					color #00aeef
					font-weight 700
					font-size .85em
					
		time
			color #00aeef
			font-weight 700
			font-size .85em

		.live-blog-post-author
			color #666
			display block
			font-size .75em

	.live-blog-post-content
		color #4E4E4E
		font-family Georgia, serif
		//float left
		//width 80%
		display block

		a
			color #00aeef

		> p:first-child
			margin-top 0
			
	&-share
		text-align right
		
		&-more
		&-link
			display inline-block
			background-color #fff
			background-position -40px -11px
			position relative
			height 21px
			width 20px
			
			img
				height 17px
				margin-top -10px
				margin-right 10px
			
			&-group
				background-color #fff
				border 1px solid #CCC
				border-radius 5px
				box-shadow rgba(204, 204, 204, 0.5) -2px 2px 1px
				font-family Arial, Helvetica, sans-serif
				font-size 12px
				display none
				margin-top 10px
				position absolute
				right 0
				top 100%
				text-align center
				width 200px
				z-index 99999999999
				
				input[type="text"]
					border 1px solid #ccc
					border-radius 2px
					box-shadow inset rgba(0,0,0,0.07) 0 3px 1px
					color #666
					font-size 14px
					min-height 21px
					line-height 1.5
					padding 2px 5px 0
					transition all .3s ease-in
					width 85%
					margin-bottom 10px
				
				&:after
					bottom 100%
					border-color rgba(255, 255, 255, 0)
					border-bottom-color #FFF
					border-width 10px
					left 90%
					margin-left -10px
				
				&:before
					bottom 100%
					border-color rgba(204, 204, 204, 0)
					border-bottom-color #CCC
					border-width 11px
					left 90%
					margin-left -11px
					
			&:before 
				content ""
				display block
				position absolute
			
			&:hover
				
				.live-blog-post-share-more-group,
				.live-blog-post-share-link-group
					display block
		
		&-more
			background url(//f.i.uol.com.br/folha/aovivo/images/sprite-20160211.png) -40px -11px no-repeat
		
		&-link
			background url(//f.i.uol.com.br/folha/aovivo/images/link.svg) 0 0 no-repeat
			height 19px
			
	img
		max-width 100%

	table.livePostImageText
		width 100%

		td
			vertical-align top

		.image
			padding-right 10px
			width 50px

			img
				max-height 50px
				max-width 50px

		.title
			font 700 18px/22px Arial, Helvetica, sans-serif
			height 24px

		.kicker
			color #ccc
			font 700 14px/18px Arial, Helvetica, sans-serif
			height 28px


	// Legado
	// .graphic
	// 	float right
	// 	margin-left 20px
	// 	max-width 35%

.live-blog-post-highlight
	background #f2fbfe
	border 1px solid #009cdc
	float left
	padding 1em
	padding-left 0
	width 100%
	
.icn-aovivo
	background url(//f.i.uol.com.br/folha/aovivo/images/sprite-20160211.png) 0 0 no-repeat
	display inline-block
	overflow hidden
	text-indent -9999px

	#timeline & 
		vertical-align top

.sprite
	background url(//f.i.uol.com.br/folha/furniture/5.4/images/sprite.png?2016021901) no-repeat rgba(0, 0, 0, 0)
	display inline-block
		
.post-btn-share
	&-twitter
	&-googleplus
	&-linkedin
		cursor pointer
		height 28px
		margin 8px 5px 3px 5px
		overflow hidden
		text-indent 999px
		width 32px
		
	&-facebook 
		background-position -10px -10px
		cursor pointer
		height 21px
		margin initial
		text-align left
		width 20px
		
	&-twitter
		background-position -61px -235px		
		
	&-googleplus
		background-position -102px -235px
		
	&-linkedin
		background-position -231px -235px
		
#uber_blackout
	position fixed
	
.uber-modal	
	width 90%
	margin 10% auto 0 auto
	border-radius 3px
	background-color #FFF
	text-align left
	max-height 90%
	overflow auto
	
	&-header
		border-bottom #bfbfbf thin solid
		padding 5px 15px
		
		&-text
			display inline-block
			color: #666;
			font-size: .85em;
			margin-top: 0;
			font-weight: 700;
			
		&-close
			display inline-block
			float right
			font-weight 700
			font-size .85em
			line-height initial
			margin-top 3px
			
	
	.live-blog-post
		border 0
		margin 15px 15px 10px
		padding initial
		
		&-period
			
			.post-wrap-time
				
				.info-game-time
					
					.game_time
						font-size 1.1em