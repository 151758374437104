.newsletter
	padding 100px 25px 
	margin 0 0 15px 
	text-align center 
	background-color #f8f8f8
	
	header
		h2
			border-bottom 3px solid #000 
			color #000 
			font 300 14px Arial, Helvetica, sans-serif 
			text-transform uppercase 
			height 21px 
			letter-spacing 3px 
			margin 0 auto 
			padding 0 0 5px
			text-align center
				
	.content
		&.thanks
			display none
		
		.text
			-webkit-margin-after 0.3em
			-webkit-margin-before 1.5em
			font-size 14px
			font-weight 500
			
	.form-group
		position relative
		
		.error
			background #000
			border-radius 5px
			color #fff
			font-size 12px
			opacity 0
			padding 7px 10px
			position absolute
			right -100%
			top -5px
			transition all .5s ease
			
			&::before
				background transparent
				border-bottom 8px solid transparent
				border-left 0
				border-right 8px solid #000
				border-top 8px solid transparent
				content ""
				height 1px
				left -8px
				overflow hidden
				position absolute
				top 12px
				width 1px
			
	.invalid
		.error
			display block
			opacity .95
			right 0	