@font-face
	font-family 'folhaii-heavy'
	src url('//static.folha.uol.com.br/fonts/folhaii-heavy/folhaii-heavy-webfont.eot') format('eot')
	src url('//static.folha.uol.com.br/fonts/folhaii-heavy/folhaii-heavy-webfont.eot?#iefix') format('embedded-opentype'),
		 url('//static.folha.uol.com.br/fonts/folhaii-heavy/folhaii-heavy-webfont.woff') format('woff'),
		 url('//static.folha.uol.com.br/fonts/folhaii-heavy/folhaii-heavy-webfont.ttf') format('truetype'),
		 url('//static.folha.uol.com.br/fonts/folhaii-heavy/folhaii-heavy-webfont.svg#folhaii-heavy') format('svg')
	font-style normal
	font-weight 400

@font-face
	font-family 'MetaSerifPro-Bold'
	src url('//static.folha.uol.com.br/fonts/metaserifpro/metaserifpro-bold.eot') format('eot')
	src url('//static.folha.uol.com.br/fonts/metaserifpro/metaserifpro-bold.eot?#iefix') format('embedded-opentype'),
		 url('//static.folha.uol.com.br/fonts/metaserifpro/metaserifpro-bold.woff') format('woff'),
		 url('//static.folha.uol.com.br/fonts/metaserifpro/metaserifpro-bold.ttf') format('truetype'),
		 url('//static.folha.uol.com.br/fonts/metaserifpro/metaserifpro-bold.svg#MetaSerifPro-Bold') format('svg')
	font-style normal
	font-weight 400

@font-face
	font-family '__Playfair Display_5'
	src url('//static.folha.uol.com.br/fonts/playfairdisplay/playfairdisplay-bolditalic.eot')
	src local('☺'), url('//static.folha.uol.com.br/fonts/fonts/playfairdisplay/playfairdisplay-bolditalic.woff') format('woff'), url('//static.folha.uol.com.br/fonts/playfairdisplay/playfairdisplay-bolditalic.svg') format('svg')
	font-weight 700
	font-style italic

@font-face
	font-family '__Gotham XNarrow_3'
	src url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-book.eot')
	src local('☺'), url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-book.woff') format('woff'), url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-book.svg') format('svg')
	font-weight 300
	font-style normal

@font-face
	font-family '__Gotham XNarrow_3'
	src url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-bold.eot')
	src local('☺'), url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-bold.woff') format('woff'), url('//static.folha.uol.com.br/fonts/gotham/gothamxnarrow-bold.svg') format('svg')
	font-weight 400
	font-style normal


body, #pagination
	font-size 16px

h1,h2,h3,h4
	font-family folhaii-heavy,Georgia,serif
	margin 0 auto
	letter-spacing 0px
	font-weight 400
	line-height 100%

h1 a,h2 a,h3 a,h4 a
	font-weight 400

h2
	margin-bottom 13px
	font-weight 400

h3
	margin-bottom 5px

h5
	margin-bottom 3px
	text-transform uppercase

a
	text-decoration none