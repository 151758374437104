.urgent 
    opacity 1
    h5
        background-color #e53935
        color #fff
        font-size 22px
        padding 10px 7px
        text-transform initial
    
        span
            text-transform uppercase
            float right
            font-size 30px
    
    p
        border-bottom 5px solid #e53935
        font-size 20px
        color #333333
        padding 15px 2px
        margin 0
        line-height 25px
        font-weight bold

.urgent-hidden
    color #FFFFFF
    transition opacity 0.5s ease-in-out
    opacity 0
