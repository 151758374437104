.article-top
	border-bottom: 2px solid #00b7f0 !important
	margin 0 !important

	a
		display inline-block

		h3
			margin 1px 0 7px 0
			color #00b7f0 !important
			font-size 1.75em !important
			text-align left !important
	    text-transform lowercase

.soccer
	.tab-container
		width 100%
		position relative
		height auto
		overflow hidden
		-webkit-transition all 0.2s ease
		transition all 0.2s ease
		.tab-group
			width (100% * 6)
			position relative
			left 0%
			-webkit-transition all 0.4s ease
			transition all 0.4s ease
			.tab.stage
				display inline-block
				position relative
				vertical-align top
				width (100% / 6)
				overflow hidden
				-webkit-transition all 0.4s ease
				transition all 0.4s ease

				header
					background-color #f9f9f9
					border-bottom 1px solid #aaa
					padding-bottom 1px
					h3
						color #000
						margin 0
						padding 20px 0
						text-align center
						font-family MetaSerifPro-Bold
						font-size 1.2em
						text-transform uppercase
					nav
						.prev-round
						.next-round
						.prev-stage
						.next-stage
							width 32px
							height 37px
							font-size: 0
							overflow hidden
							&:before
								content ''
								display block
								margin 12px 0 0 12px
								width 8px
								height 13px
								background-image url(//f.i.uol.com.br/app/2/sprite-app-17.png)
						.prev-round
						.prev-stage
							float left
							margin -48px 0 0 0
							&:before
								background-position -176px -398px
						.next-round
						.next-stage
							float right
							margin -48px 0 0 0
							&:before
								background-position -193px -398px

				.content
					width 100%
					.game
						margin 0
						padding 0
						border 1px solid #00b7f0
						height 59px
						.info
							text-align center
							font-family Arial
							font-weight bold
							font-size 0.63em
							line-height 10px
							padding 6px 0 0 0
						h4
							width 100%
							display inline-block
							margin-top 4px
							.vs
								width 14%
								height 26px
								display inline-block
								float left
								font-size: 0
								&:before
									content ''
									display block
									position relative
									margin 10px auto 0 auto
									background-image url(//f.i.uol.com.br/app/2/sprite-app-17.png)
									background-position -137px -402px
									width 7px
									height 7px
									overflow hidden
							.away
								text-align right
							.home
							.away
								width 43%
								height 26px
								display block
								float left
								font-family Helvetica Neue
								font-size 0.9em
								.team
									width 40%
									display block
									float left
									text-align center
									height 26px
									line-height 26px
								.flag
									width 20%
									display block
									float left
									height 26px
								.goals
									width 40%
									display block
									float left
									font-family Helvetica
									font-weight bold
									height 26px
									.score
										font-size 1.3em
										line-height 26px
									.pkscore
										font-size 1em
										line-height 26px
									@media (max-width: 340px)
										.score
											font-size 1em
										.pkscore
											font-size 0.8em
								.undefined
									width 100%
									height 26px
									display block
									text-align center
									font-size 0.9em
									line-height 26px
							.home.winner
							.away.winner
								.team
									font-weight bold
									color #333333
							.home.loser
							.away.loser
								.team
									color #666666
							.home
								.goals
										text-align right
							.away
								.goals
										text-align left

					.matches
						margin 0
						padding 0
						.match
							width 91%
							padding 0
							&:nth-of-type(odd)
								margin 10px 0 0 0
							&:nth-of-type(even)
								margin 6px 0 18px 0
							&:nth-of-type(odd):after
								content ''
								float right
								width 9%
								height 126px
								overflow: hidden
								display inline-block
								margin -60px -9% 0 0
								background url(//f.i.uol.com.br/app/2/chaveamento.png) no-repeat
							.game
								&:first-child
									border-bottom 1px dashed #aaa
								&:last-child
									border-top 0
							
							.game.game-border
								&:first-child
									border-bottom 1px solid #00b7f0
								&:last-child
									border-top 0
								
					.round
						margin 0
						padding 0
						h3
							margin 10px 0
							text-align center
						.game
							margin 10px 0

			.tab.stage.pre
				.match
					margin 10px 0 !important
				.prev-stage
					display none

			.tab.stage.final
				.next-stage
					display none

			.tab.stage.grupos
				.content
					width (100% * 6)
					position relative
					left 0%
					-webkit-transition all 0.4s ease
					transition all 0.4s ease
					.round
						width (100% / 6)
						float left

			.tab.stage.pre
			.tab.stage.final
				.match
					width 100% !important
					&:nth-of-type(odd):after
						display none !important
