.championshipRound
    display block
    position relative
    vertical-align top
    width 100%
    h2
        display: block
        border-bottom: 2px solid #00aeef
        color: #00aeef
        font-family: metaserifpro-bold,Georgia,serif
        line-height: 160%
        margin-bottom: 0
        text-transform: lowercase
        a, a:hover, a:visited, a:active
            color: #00aeef

    h3
        padding 0.3em 0 0.5em
        margin-top 0
        margin-bottom 0
        text-align center
        text-transform capitalize
        font 300 17px "metaserifpro-bold", Georgia, serif
        background-color #F7F7F7
        border-bottom 1px solid #000
    .game
        border-bottom 1px dashed #C3C3C3
        display block
        list-style-type none
        padding-top 15px
        padding-bottom 15px
        position relative
        h4
            font-size 1em
            font-weight normal
            line-height 50px
            color #555
            margin 0
            position relative
            text-align center
            .score
                font-size 1.5em
                display table-cell
                color #000
                width 10%
                font-family 'folhaii-heavy', Georgia, serif
                font-size 27px
            .vs
                display inline-block
                color #000
                width 5%
            .flag
                display inline-block
                width 20%
            .home,
            .away
                display table
                width 47.5%
            .home
                float left
                .team
                    width 20%
                    display table-cell
                    text-align center
                    vertical-align 0.1em
                .flag
                    left 0
                    display table-cell
                    img
                        vertical-align: middle
            .away
                float right
                .team
                    width 20%
                    display table-cell
                    text-align center
                    vertical-align 0.1em
                .flag
                    display table-cell
                    img
                        vertical-align: middle
        .info
            color #666
            font-size 0.7em
            position absolute
            font-weight bold
            text-align center
            top 55px
            text-transform uppercase
            width 100%
    .game-list
        padding: 0
        li
            list-style-type none
    .connect
        position relative
        padding-left 50%
        width 50%
        .bottom
            border-top 1px solid #ddd
    .tab-container
        display block
        min-height 200px
        overflow hidden
        position relative
    .tab-group
        display block
        transition all 0.4s ease
        transform translateX(0)
        white-space nowrap




    .championship-round 
        .game
            text-align center
            color #555

        .team
            float left
            padding 0 5px
            width 33.66%

        .name
            display inline-block
            font-size 9px
            font-weight 700
            margin-top 10px
            overflow hidden
            text-overflow ellipsis
            text-transform uppercase
            white-space nowrap
            width 100%

        img.flag
            height 35px
            width 35px

        .score
            padding-top 8px
            font-weight 700
            float left
            padding-top 3px
            width 31.66%
            line-height 22px

            .info
                color #00aeef
                font-size 10px
                text-transform uppercase
                display block

            span.team-a
                margin-right 10px
                font-size 20px
                line-height 22px

            span.team-b
                margin-left 10px
                font-size 20px
                line-height 22px

 
    .game
        height 82px
        line-height 0.5em

        .info
            position initial
            top 0





.championshipTable
    h2
        border-bottom: 0px
        a, a:hover, a:visited, a:active
            color: #00aeef
    .group
        width 100%
        thead
            th
                padding-bottom 10px
                padding-top 12px
                &:first-child
                    padding-left 1%
        tbody
            tr
                border-top 1px dashed #ddd
            td
                padding 5px 0
        th,
        td
            text-align center
            width 13%
        .bold
            font-weight bold
            width 7%
        .text-col
            text-align left
            width auto
            .flag
                margin-right 10px
                margin-left 10px
        .number-col
            text-align center
            width auto
        .promoted,
        .demoted
            background #4a8afe
            display inline-block
            height 25px
            color #fff
            width 30px
        .demoted
            background #f9d72a
    h2
        display: block
        border-bottom: 2px solid #00aeef
        color: #00aeef
        font-family: metaserifpro-bold,Georgia,serif
        line-height: 160%
        margin-bottom: 0
        text-transform: lowercase
    h3
        padding 0.3em 0 0.5em
        margin-top 0
        margin-bottom 0
        text-transform capitalize
        text-align center
        font 300 17px "metaserifpro-bold", Georgia, serif
        background-color #F7F7F7
        border-bottom 1px solid #000
    a
        &:focus
            text-decoration none
    .button
        background-color #00adef
        border-radius 2px
        color #FFF
        padding 7px 15px 7px
        font-weight bold
        font-size 15px
    .text-center
        text-align center
        margin-top 20px
        margin-bottom 25px
        width 100%
        height 25px