.editorial.curinga-header .item.phrase .icon-quotation-marks, .mention .item.phrase .icon-quotation-marks
    float left
    margin 0 10px 0 0
    background-position -280px -152px
    display block
    width 57px
    height 38px
    text-indent -9999px
    padding-bottom 50px


.editorial,.three-headline-top
	border-bottom 1px solid #ccc
	padding-bottom 10px
	margin-bottom 10px
	color #666

	/.editorial, &.curinga-header > div
		//clearfix para imagens flutuadas
		clear both
		overflow auto
		zoom 1 

		.more
			text-align center
			padding 7px

	h2, /.three-headline-top h2
		font-size 2.2em
		margin-top .5rem

		a
			line-height 2.3rem
	
	/.three-headline-top h2
		font-size 3.2em
	h3, & a
		line-height 28px

	h3
		font-size 1.6em
		color #000
		text-transform capitalize

	h4
		font-family Georgia,serif
		font-size 1.2em
		color #000

	h5
		font-size 0.9em
		margin 0 0 2px 0

	.item.phrase
    	margin 15px 0
    	overflow hidden

	.more
		background #00aeef
		color #fff

		a 
			color #fff
			text-transform uppercase

	.section-title
		font-family 'MetaSerifPro-Bold',Georgia,serif
		text-transform lowercase
		font-size 2.5em
		border-bottom 2px solid
		padding-bottom 5px
		margin-bottom 0
	a
		line-height 20px
		margin-bottom 10px
		font-size 1em
		color #222

	&>a
		//font-size 1.62em
	img
		width 100%
		display block
		border 0
		margin 0 auto 5px auto

	.last
		border-bottom 0 none
		padding-bottom 0
	&last-child
		border-bottom 1px solid #ccc
		padding-bottom 20px

	&.space-ghost
		display none

	.intro
		display inline

	.lide
		padding-top 0px!important
		clear both
		
	&.double-headline
		overflow  visible
		h2
			font-size 2.2em
			margin-top .5rem

	.image_150x115,.image_35x35,.image_70x70
		float left
		margin 0 10px 10px 0

	.image_35x35
		height 35px
		width 35px

	.image_70x70
		height 70px
		width 70px

	.image_150x115
		height 115px
		width 150px
	
	&.curinga-header
		border-bottom 0px
		clear both
		overflow auto
		zoom 1

		.curinga_title > h5, &> h5
			border-bottom 2px solid #00aeef
			padding-bottom 8px
			margin-bottom 15px
			text-transform lowercase
			margin-top 15px
			font-family "metaserifpro-bold", Georgia, serif
			font-weight 300
			font-size 1.6em
	&.video-article, &.gallery-article
		background none
		text-align left

		.section-title
			padding-bottom 0px
			font-size 1.6em
			line-height 1.6em
			
	&.no-line
		border 0
		margin-bottom 0

.mention
	.item.phrase
		margin 15px 0 0 0
		overflow hidden
	a
		line-height 20px
		margin-bottom 10px
		font-size 1em
		color #222

	&>a
		//font-size 1.62em
	h4
		font-family Georgia,serif
		font-size 1.2em
		color #000
	footer
		clear both
		color #333
		font-size 11px
		padding 5px 0
		text-align left

		strong
			text-transform uppercase