.barrauol
    display block


.tm-ads
    width 100%


.ad-wrapper
    background #fff url(//f.i.uol.com.br/folha/furniture/ads/bg-publicidade-6x6.png) repeat 0
    text-align center
    position relative

.ads-bottom
    display block
    width 100%
    text-align center
    position relative

.ads-top
    width 100%
    display block
    text-align center

.banner-728x90
    width 728px
    height 90px
    text-align center

.banner-1x1
    position absolute

.banner-320x50
    width 320px
    height 50px
    text-align center

.banner-300x50
    width 300px
    height 50px
    text-align center

.banner-300x250
    width 300px
    height 250px

.banner-300x250,.banner-300x50,.banner-320x50, .banner-300x250-3
    margin-auto

.ads-block-modal
    border-bottom 1px solid #ccc
    padding-bottom 20px
    margin-bottom 20px
    text-align center

.modal .image
    position relative

.ads-block-modal
    border 0
    padding 0
    margin 0
    text-align center
    position absolute
    left 50%
    top 50%
    margin-left -150px
    margin-top -135px

    iframe
        padding 0


    .ad-tag
        color #fff
        background transparent

.ad-300x250
    width 300px
    display inline-block
    height 250px
    margin auto
    background #ccc

.ad-300x600
    width 300px
    display inline-block
    height 600px

.ad-300
    width 300px
    display inline-block
    height 250px
    margin auto

.ad-320
    width 320px
    display inline-block
    
    .ad-tag
        margin-bottom -15px
        position absolute
        top -15px
        right 0
        background transparent

.ad-320.ads-bottom
    height 50px

.ad-728
    width 728px
    display inline-block


.ad-tag
    font-family helvetica,arial,sans-serif
    background #fff
    color #ccc
    display block
    font-size 10px
    line-height 1.5
    text-align right
    text-transform uppercase
    padding-bottom 0


    &.vertical-right
        position absolute
        text-align center
        //-ms-transform rotate(90deg)
        //-webkit-transform rotate(90deg)
        transform rotate(90deg)
        display none


.ads-modal
    &.ad-300
        height 265px



/**
 * Middle
 */
.ad-middle-container
    height 250px
    margin-bottom 20px
    border-bottom 1px solid #ccc


/**
 * Top
 */
.ad-top-container
    display block
    margin-bottom 5px
    width 100%
    height 50px

    &.origin-capa
        display none

/*
*Middle
*/
.ad-middle-container
    display block
    margin-bottom 15px
    width 100%
    height 265px

/**
 * Bottom
 */
.ad-bottom-container
    width 100%
    overflow hidden

.opiniao
    .ads-three-columns
        margin-bottom 15px
        
.close-ad
    width 30px
    height 30px
    position absolute
    right 0
    top 0
    z-index 25
    cursor pointer
    float right
    background #989898
    border-radius 20px
    border 2px solid #fff
    box-shadow 5px 4px 8px -2px rgba(69, 68, 68, .87)
    color #fff
    line-height 28px
    margin 5px


/**
 * Fixed ads bottom
 */
 
.ad-bottom-fixed
    width 100%
    height 50px
    display block
    float left
    position fixed
    bottom 0
    background #fff

    .banner-320x50-1 iframe
        display block
        margin 0 auto