#splash 
	display none 

#bar, #main 
	display block 


#blackout 
	display none
	background rgba(68,68,68,.6)
	width 100%
	height 100%
	min-height 400px
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	z-index 10
	top 0px


#uber_blackout 
	display none
	background rgba(0,0,0,.8)
	width 100%
	height 100%
	min-height 400px
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	z-index 20
	text-align center
	.filter-property
		background #fff
		color #6F6F6F
		font-size 16px
		font-weight 700
		line-height 24px
		padding 10px 8px
		text-align left
		text-transform uppercase

		.icon-filter
			background-position -304px -231px
			height 24px
			vertical-align top
			width 20px

		.close-button
			color #000
			float right


#uber_whiteout 
	display none
	background 0 0
	width 100%
	height 100%
	min-height 400px
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	z-index 10
	text-align center


.modal 
	width 100%
	height 100%
	min-height 100%
	color #fff
	vertical-align middle
	text-align center
	position fixed


	&.iframe 
		width 100%
		height 100%
		margin-top 0
		background #fff
		overflow scroll
		-webkit-overflow-scrolling touch
		
	.image 
		height 100%
		position absolute
		width 100%
		max-height 100%
		left 50%
		top 50%
		transform translateX(-50%) translateY(-50%)
		overflow auto
		-webkit-overflow-scrolling touch

	img
		width auto
		max-width none
		height auto	

	iframe
		width 100%
		padding 20px
		border 0
		height 100%

	.close 
		width 30px
		height 30px
		position absolute
		right 10px
		top 5px
		z-index 25
		cursor pointer
		float right
		background #989898
		border-radius 20px
		border 2px solid #FFF
		box-shadow 5px 4px 8px -2px rgba(69,68,68,0.87)
		color #fff
		text-align center
		line-height 23px
		//font-size 22px
		margin 5px


	&.tutor .close 
		display none


		img 
			max-width 100%

.modal-filter
	text-transform uppercase
	font-size 16px
	font-weight 700
	line-height 24px
	color #6F6F6F
	padding-top 10px
	.filter-property
		display none
