.box.apuracao
	icon
		background url(//f.i.uol.com.br/folha/especial/2016/eleicoes/images/ico-apuracao-16x16.png) no-repeat center center transparent
		float left
		height 16px
		margin 2px 6px 0 0
		width 16px

	.box-title .icon-right
		background url(//f.i.uol.com.br/folha/especial/2016/eleicoes/svg/arrow_right.svg) center left no-repeat
		display inline-block
		height 13px
		margin 0 0 0 10px
		padding 0
		width 13px

	#loading
		width 32px
		height 32px
		display block

	&.apuracao-sp
		&.headline
			.box-header .box-title
				border-bottom 0px
				font-size 1.2em
				padding-bottom 0
				text-transform uppercase

		.box-item
			width 100%
			margin 15px 0 -10px 0

		.name
			font-size 16px
			font-family 'Roboto'
			font-weight bold
			width auto
			float none

		.box-item-image img
			height 98px

		 .votacao
			font-size 24px
			line-height 40px
			padding 0 0 18px 0
			display block

		.eleito .votacao
			font-size 32px

		.partido
			color #949ca7

	&.prefeitos.headline
		.box-header .box-title
			border-bottom 0px
			font-size 1.2em
			padding-bottom 0
			text-transform uppercase
		.name
			float none
			width auto
			font-size 12px

	.text
		color #333333
		display inline-block
		font-family 'Roboto'
		font-size 14px
		font-weight normal
		line-height 1.43
		margin-bottom 10px
	
	.info
		height 18px
		font-size 14px
		font-weight bold
		line-height 1.29
		color #00559d
		width 100%
		display inline-block
	
	.update
		height 11px
		font-size 11px
		line-height 1.27
		color #949ca7
		width 100%
		display inline-block

	.box-item
		width 45%
		float left
		margin-right 5px
	
	
	.box-item-image
		width auto
		float left
		margin 0
		padding-right 5px

		img
			width auto
			height 85px
			margin 0
			padding 0
	
	.classificacao
		width 13px
		height 12px
		border solid 1px #00acf2
		font-family 'Roboto'
		font-size 12px
		font-weight normal
		line-height 1
		color #00acf2
		padding 0px 4px
	
	.name
		margin 2px 0 0 0
		width 69px
		height auto
		font-family 'Roboto'
		font-size 14px
		line-height 1.17
		color #333333
		float left
		font-weight bold
		
	
	.partido
		font-family 'Roboto'
		font-size 12px
		font-weight normal
		line-height 1.5
		color #6b6b6b
		margin 15px 0 0 0
	
	.votacao
		font-family 'Roboto'
		font-size 24px
		font-weight bold
		line-height 1.5
		color #00acf2
		margin 0
		display inline-block
		width 100%

	.result 
		.name
			margin-right 10px
			width auto
	
		.votacao
			float right
			font-family 'Roboto'
			font-size 14px
			font-weight bold
			line-height 1.43
			text-align left
			color #6b6b6b
			width auto
	
		div
			margin 10px 0
			width 100%
			display inline-block

	.elected
		.box-item-image:after
			content 'Eleito(a)'
			background-color #e36f25
		.votacao
			color #01579b
	
	.second_turn .box-item-image:after,
	.second_round .box-item-image:after
		content '2� turno'
		background-color #e6a114
	
	.elected .box-item-image:after,
	.second_turn .box-item-image:after,
	.second_round .box-item-image:after
		width 100%
		height 18px
		display block
		color #fff
		text-align center
		line-height 18px
		font 12px 'Roboto'
		margin -18px 0 0 0
		position relative
	
	button
		background #fff
		border-radius 4px
		border 1px solid #ccc
		box-shadow inset rgba(0,0,0,0.07) 0 3px 1px, rgba(0,0,0,0.05) 0 2px
		color #666
		cursor pointer
		display inline-block
		font-size 10px
		font-weight 700
		line-height 1.4
		padding 7px 40px 5px
		transition all .3s ease
		text-transform uppercase
		font-family arial
		margin-top 10px

	form.apuracao-filter
		margin-bottom 9px

		input[type=text], select
			display inline-block
			padding 5px 10px
			font 15px 'Roboto'
			line-height 18px
			color #8a9493
			border-radius 3px
			background-color #fff
			border solid 1px #dedede

		select
			padding 5px 10px
			width 100%

			option
				display block
				font 15px 'Roboto'
				line-height 18px
				color #8a9493
				background-color #fff
				padding 8px 10px

			option[disabled]
				color #ccc

.box-title-image, .map
	display none

.apuracao.segundo_turno span
	width 304px
	height 18px
	font normal 14px Helvetica,Arial,sans-serif
	line-height 1.29

.box.apuracao form.apuracao-filter select
	margin-top 13px


.elections-city
	.state
		display none

	.porcent
		width 100%
		float left
		margin-bottom 10px
		height 30px

		.number
			width auto
			height 18px
			font 700 14px Arial, Helvetica, sans-serif
			line-height 1.29
			color #00559d
			float left
			margin 0 5px 0 3px

		.barra
			width 177px
			height 5px
			background-color #cccccc
			float left
			margin 7px 0 0 0

		.progress
			float left
			height 5px
			background-color #e7e7e7
			box-shadow inset -1px -1px 18px #cccccc, inset 0 -1px 0 #cccccc
			margin 6px 0 0 0
			width 300px

		.progress-bar
			width 0
			height 5px
			background-color #00559d
			-webkit-transition width .6s ease
			transition width .6s ease

		.update
			width auto
			height 14px
			font 300 10px Arial, Helvetica, sans-serif
			line-height 1.4
			color #666666
			float right
			margin-top 5px

	.candidate::after
		border-radius 50%
		box-shadow inset 0 0 0 2px rgba(239, 239, 239, 1), inset 0 0 5px 1px rgba(239, 239, 239, .5)
		content ''
		display block
		height 74px
		left 0
		position absolute
		top -1px
		width 74px
		
	.candidate
		width 100%
		float left
		margin-bottom 20px
		position relative

		.media-image
			display block
			height 72px
			position relative
			overflow hidden
			width 72px
			float left
			border-radius 50%
			margin-right 18px

		.elected .media-image
			border 0
			box-shadow none
			margin-right 16px

		img
			width 100%

		.name
			width 214px
			height 14px
			font 700 12px Arial, Helvetica, sans-serif
			line-height 1.17
			color #333333
			float left
			margin-top 9px

		.partido
			width 214px
			height 18px
			font 300 11px Arial, Helvetica, sans-serif
			line-height 1.64
			color #6b6b6b
			float left

		.votacao
			width 88px
			height 20px
			font 700 20px Arial, Helvetica, sans-serif
			font-size 20px
			line-height 1
			color #01579b
			float left

		.elected-flag
			font-size 11px
			font-weight bold
			line-height 1.09
			color #ffffff
			background-color #00559d
			padding 2px 5px
			text-transform uppercase
			float left

	.elected::after
		box-shadow inset 0 0 0 2px rgba(1, 87, 155, 1), inset 0 0 5px 1px rgba(1, 87, 155, .5)

.voting
	font 700 12px Arial, Helvetica, sans-serif
	color #00B0EF
	width 100%
	text-align center
	float left
	margin 5px 0
	a
		text-decoration none

	.icon-right
		background url(//f.i.uol.com.br/folha/especial/2016/eleicoes/svg/arrow_right.svg) center 0 no-repeat
		background-size 6px
		display inline-block
		height 10px
		margin 0
		padding 0
		width 13px

.box.apuracao.apuracao-na_minha_cidade div[data-elections-my-city-msg]
	color #01579b
	font-size 12px
	margin-top 12px
	font-weight bold