.article-top
	margin 25px 0

	img
		width 100%

	&.no-image
		border-bottom 2px solid #006

		h3
			font-family "metaserifpro-bold", Georgia, serif
			font-size 1.5em
			color #006
			text-align center
			
		&.special-light-blue
			border-color #00b7f0
			
			h3 
				color #00b7f0

.special-news-list
	font-size 1.5em

	.special-news-list-title
		display block
		border-bottom 2px solid #e71c22
		color #e71c22
		font-family "metaserifpro-bold", Georgia, serif
		line-height 160%
		margin-bottom 15px

	.list
		list-style none inside
		padding 0
		font-size 0.8em

		li.date
			border-bottom 0
			padding-bottom 0
			margin-bottom 10px
			font-weight 700
			font-size 0.9em

		li
			padding-bottom 15px
			border-bottom 1px solid #ccc
			margin-bottom 15px
			list-style none inside

			a
				color #000

			time
				display block
				color #555
				font-weight 700
				font-style italic
				font-size 0.8em
				margin-bottom 3px

				.icon-clock
					margin-left 0

