/*
 * Live Blog com Tags
 *
 * @author	Laura Leamari - (l.nardi) <laura.leamari@grupofolha.com.br>
 * @since	2016
 */

// Modal

.modal-filter.modal-tags 
	#filter
		input[type=radio]
			display none


	.icon-filter, .live-blog.live-tags #uber_blackout .filter-property .icon-filter
		background url('//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/images/f-icon.png') no-repeat center
		height 24px
		display inline-block
		vertical-align top
		background-position 0
		width 20px

	.more-tags
		display none
		float right
		margin-top 7px


    .hidden-tags
		display none

	.hidden-tags label
		display block

	.more-tags:hover .hidden-tags
		display block
		position absolute
		white-space nowrap
		right 14px
		margin-top 7px
		z-index 2
		border 1px solid #a1a090
		border-top 2px solid #00adef
		background #fff


	.more-tags span:after 
		content ""
		display inline-block
		vertical-align middle
		margin-left 5px
		margin-top -2.5px
		width 0;
		height 0;
		border-left 5px solid transparent
		border-right 5px solid transparent
		border-top 5px solid #a1a090

	.more-tags span.active:after, .more-tags span:hover:after, .more-tags:hover span:after 
		border-top 5px solid #00adef;


	label, .more-tags span, label a 
		color #a1a090
		font normal 14px Arial, Helvetica, sans-serif
		padding 10px 10px
		text-transform uppercase
		cursor pointer


	label 
		display inline-block
		max-width 100%
		margin-bottom 5px
		font-weight 700


	input[type=radio]:checked + label, input[type=radio]:checked + label a, .more-tags:hover span, label:hover, label a:hover, .more-tags span.active
		color #00adef
		font-style normal


	label
		width 50%
		border-bottom 2px solid #fff
		padding-bottom 5px
		vertical-align top

	input[type=radio]:checked + label
		border-bottom 2px solid #00adef

	label.first
		margin-top 10px


	#active_filter
		float right
		color #00adef
		border-bottom 3px solid #00adef

// Date
.live-blog.live-tags 

	.live-blog-post .live-blog-post-period .date-month
		color #a1a090
		font-size 15px
		font-weight 500
		line-height 1.33
		border-left 1px solid #a1a090
		margin-left 5px
		padding-left 5px

	.post-time-send.date-month
		border-top 1px solid #a1a090
		margin-top 5px
		padding-top 5px


// Tags
	#narration .post-tag, .live-blog-post-tag
		font-size 13px
		color #fff
		margin-bottom 10px
		padding 5px 10px
		font-family Arial, Helvetica, sans-serif
		display inline-block
		letter-spacing 1.7px
		text-transform uppercase
		border-radius 1px
		background-color #00adef