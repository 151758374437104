.share
	margin 18px 0px

	ul li
		display inline-block
		margin-right 5px

	.email, .google, .twitter, .facebook, .whatsapp, .link, .linkedin
		display block
		width 39px
		height 38px
		text-indent -9999px

	.facebook
		background-position -14px -272px
	.twitter
		background-position -111px -272px 

	.google
		background-position -160px -272px

	.email 
		background-position -256px -272px
	.whatsapp
		background-position -63px -272px
	.link
		background-position -304px -272px
	.linkedin
		background-position -208px -272px
