.headline.curinga-header.apuracao-carnaval
    .box-title
        border-color #a1b808
        .info
            font-size 0.6em
            float right
            color #000
            
    .box-header
        color #a1b808

    .box.apuracao .info
        color #777
        font-size 10px
        position absolute
        right 0
        text-transform uppercase
        top 10px

    .table-apuracao,
    .table-rebaixadas
        width 100%

    .table-apuracao tr
        border-bottom 1px solid #ccc
        height 32px

    .table-rebaixadas tr
        border-top 1px solid #ccc
        height 32px

    .table-apuracao td,
    .table-rebaixadas td
        vertical-align middle

    .table-apuracao tr td:first-child
        background-color #eee
        color #a1b808
        font-weight bold
        text-align center
        padding 10px
        width 32px

    .table-apuracao .escola,
    .table-rebaixadas .escola
        font-size 14px
        padding-left 5px
        width 235px

    .table-apuracao .nota,
    .table-rebaixadas .nota
        color #a1b808
        font-weight bold
        padding-right 5px
        text-align center

    .table-rebaixadas tr 
        td:first-child
            background #a1b808
            color #fff
            font-weight bold
            text-align center
            width 32px

    .subtitle
        color #a1b808
        font 300 14px/1.5 Arial, Helvetica, sans-serif
        font-weight bold
        padding 3px 0 1px
        text-indent 10px
        text-transform uppercase

    .rotate-container .controls-nav-item.active
        background-color #a1b808