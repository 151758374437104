.tag-editoria-enviesuanoticia, .tag-editoria-obrigado
	
	section
		background #d5e23c
	h1 a
		color #00adef
	
.envie-sua-noticia

	.simple-text .content
		a
			color #00adef
	fieldset
		border none 
		padding  0
		margin  0

	.form
		legend
			display block
			width 100%
			padding 0
			margin-bottom 20px
			font-size 1.2em
			line-height 40px
			color #333333
			border 0
			border-bottom 1px solid #e5e5e5
		select, input[type="file"]
			height 30px
			line-height 30px

    label
		display initial
		color #000


	.form-horizontal
		.control-group
			margin-bottom 20px
		
		.control-group
			margin-bottom 20px
			zoom 1
		
		.control-group:before,
		.control-group:after
			display table
			content ""
			line-height 0
		
		.control-group:after
			clear both
		
		.control-label
			float left
			width 90%
			padding-top 5px
			text-align left
			display none
		
		.controls input[type="text"],
		.controls input[type="file"]
			width 96%

		.controls textarea
			width 96%

		
		.help-block
			margin-bottom 0
		
		input + .help-block,
		select + .help-block,
		textarea + .help-block,
		.uneditable-input + .help-block,
		.input-prepend + .help-block,
		.input-append + .help-block
			margin-top 10px
		
		.form-actions
			padding-left 180px
		
		.controls input#auth
			width 55px
			float left
			margin-bottom 30px
		
		.controls .checkbox.inline h5
			margin 0
		
		.controls input.btn
			width 100%
		

	.radio input[type="radio"],
	.checkbox input[type="checkbox"]
		float left
		margin-left -20px
	
	.controls > .radio:first-child,
	.controls > .checkbox:first-child
		padding-top 5px
	
	.form-search .radio input[type="radio"],
	.form-search .checkbox input[type="checkbox"],
	.form-inline .radio input[type="radio"],
	.form-inline .checkbox input[type="checkbox"]
		float left
		margin-right 3px
		margin-left 0
	
	.control-group
		margin-bottom 10px
	
	legend + .control-group
		margin-top 20px
		-webkit-margin-top-collapse separate
	

	.controls > .checkbox:first-child
		width 100%
		float left
	