.beaches-quality
/* Beaches */
	margin-top 2rem

	&>h2
		font-size 2em
	.good, .bad
		line-height 25px


	.good i, .bad i
		float left
		height 25px
		margin 0 5px 5px 0
		padding-left 25px


	.good i
		background url(//f.i.uol.com.br/folha/cotidiano/images/praias/icn-bandeira_verde-21x22.png) 0 0 no-repeat

	.bad i
		background url(//f.i.uol.com.br/folha/cotidiano/images/praias/icn-bandeira_vermelha-21x22.png) 0 0 no-repeat


	/* Search */
	.search 
		.group
			padding-left 0

			label
				background #f8f8f8
				color #666
				font-size 1.2rem
				font-weight 700
				line-height 38px
				padding .8rem
				width 100%
				display block

				@media only screen and (min-width 768px)
					float left
					width

			select
				background #fff
				border-color 1px solid #999
				border-radius 4px
				color #444
				padding .4rem
				width 100%

				@media only screen and (min-width 768px)
					float right
					width 50%
					margin-left 5%
		.box-cities
			padding-right 0

		&:after
			clear both
			overflow auto
			zoom 1 

	.city
		border-top 1px solid #eee
		margin-top 1.2rem

		h3
			margin-top 1.2rem
			font-size 2em


	.city-update, .status, .cities
		color #777


	/* Status */
	.status
		background #f8f8f8
		margin .8rem 0
		padding .6rem .8rem .3rem


		.item
			float left
			margin-right 1.5rem
			text-transform uppercase


	/* City-beaches */
	.city-beaches
		border-top 1px solid #eee
		font-size 1.1rem
		list-style none outside none
		margin-top .8rem
		padding 1rem 0

		li
			display block
			font-size .8rem
			padding-top .5rem
			width 100%

			@media only screen and (min-width 768px)
				display inline-block
				width 33%