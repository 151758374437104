.rotate
	width 100%
	margin-bottom 25px

	a
		color #222

	.credit
		display block
		color #444
		font-size 11px
		line-height 13px
		text-align left!important

	.controls
		text-align center
		overflow hidden
		width 100%
		height 40px

		.button-prev, .button-next
			background #eee
			display inline-block
			padding 10px 0px
			text-align center
			position relative
			text-indent -9999999px
			overflow hidden
			width 49.80%

		.button-prev
			float left
			margin-right 0.12%

		.button-next
			float right
			margin-left 0.12%


		.arrow
			background-image url(//f.i.uol.com.br/app/2/sprite-app-8.png)
			display inline-block
			height 17px
			width 12px
			position absolute
			left 50%
			background-position -335px -99px

			&.next
				//-webkit-transform rotate(180deg)
				//-moz-transform rotate(180deg)
				//-ms-transform rotate(180deg)
				//-o-transform rotate(180deg)
				transform rotate(180deg)

	.content
		width 100%
		border 2px solid #eee
		padding 2px
		border-top 0px
		>:nth-child(4)
			border-bottom 1px solid #ddd
			margin-bottom 10px

		.description
			font-size 16px
			padding 2px 5px

	.photo
		margin 0
		display block

		img
			margin 0
			display block
			width 100%
