.section-head

	display block
	width 100%
	margin 0 0 15px 5px
	position relative

	h1
		font-family 'MetaSerifPro-Bold',Georgia,serif
		text-transform lowercase
		z-index 1
		font-size 2.15em
		line-height 46%
		position absolute
		bottom 6px
		left -5px

		@media only screen and (min-width 768px)
			font-size 2.8em
			bottom 8px
		a
			color inherit
	section
		background #00b7f0
		height 34px
		text-align right
		padding 10px

	.date
		display none

	&.tag-editoria-empreendedorsocial h1
		@media only screen and (max-width 370px)
			font-size 1.8em
			color #036

/*
* Estilo para todas editorias que comp�em o F5
*/
.f5
.bichos
.celebridades
.factoides
.fofices
.humanos
.saiunonp
.televisao
.voceviu

	.section-head
		margin-left 0

		h1
			top 0
			left 20px
			width 49px
			height 43px
			background url('//f.i.uol.com.br/app/2/title-f5.png?2015') top left no-repeat
			overflow hidden
			color transparent

		section
			background #eee
			height 43px

.tvfolha .section-head .mini-logo a
	background-position left bottom


.tvfolha .section-head .date
	color #333

/*
* Estilo para as editorias indice
*/

.list-fol
	padding 0

	li
		list-style: none inside
		font-size: 21px
		line-height: 24px
		font-family: Georgia,serif
		letter-spacing: -1px
		margin-bottom: 15px
		border-bottom: 1px solid #ccc
		padding 0 0 15px 0px
		
		section
			float left
			padding-right 10px
			font-family 'MetaSerifPro-Bold',folhaii-heavy,Georgia,serif
			text-transform lowercase

		time
			font-size: 16px
			color: #555
			display: block
			padding-top: 3px
			font-family: MetaSerifPro-Bold,folhaii-heavy,Georgia,serif
			text-transform: lowercase

		&.date
			margin 20px 0
			font-family 'MetaSerifPro-Bold',Georgia,serif
			text-transform lowercase
			text-align left
			font-size 1.9em
			border-bottom 3px solid #00b7f0
		a
			color inherit
		
		.color-colunistas
			color #900

/*
* Estilo para colunas e blogs
*/

.colunas-blogs
	margin-bottom 20px

	.list-fol
		li
			.color-colunistas
				color #00aeef

	.item
		ul
			padding 0
			width 100%

			li
				border-bottom 1px solid #eee
				padding 10px 0 5px

		p
			margin 5px 0 5px

			.complement
				color #666
				font-size 14px
				font-weight 100

			a
				color #00aeef
				font-size: 16px
				font-weight bold
				line-height: 24px
				text-transform uppercase

		h3
			font-family Arial, Helvetica, sans-serif

			.complement
				color #666
				font-size 14px
				font-weight 100

			a
				color #00adef
				font-size 16px
				font-weight bold

		.description
			margin 0 0 10px

			a
				color #000
				text-transform none

	.reference
		display block
		position relative
		top -90px
		visibility hidden

	.menu-letras
		font-weight bold

	ol
		list-style none
		margin-bottom 0
		margin-top 15px
		padding 0

		li
			display inline-block
			margin-top 5px
			text-align center
			width 15.4%

			a
				border-bottom 3px solid #fff
				color #6F6F6F
				display block
				padding-bottom 5px

				&:hover, &:focus
					border-bottom 3px solid #00aeef
					color #00aeef

			.inactive
				color #ccc
	.icon-filter
		background-position -304px -231px
		height 24px
		vertical-align top
		width 20px

.colunas-blogs-select
	border-radius 5px
	color #666
	font-size 16px
	font-weight bold
	margin 10px 0
	padding 4px
	text-transform uppercase
	width 100%

.columnist-top-full
	margin-bottom 40px

	.top-wrapper
		background-color #900

		h2
			margin-bottom 0			

			a
				color #fff
				display block
				padding 10px 0
				text-align center

	.biography
		background-color #eee
		display inline-block
		padding 10px
		width 100%

		.image
			border-radius 50%
			float left
			height 70px
			margin-right 20px
			overflow hidden
			width 70px

		img
			width 100%

		p
			display table
			font-size 1.125em
			margin 0

.columnist-contact-info
	font-family Arial, Helvetica, sans-serif
	margin-top 16px	

	ul
		li
			a
				color #666
				font-size 14px
				font-weight bold

	.sprite
		height 18px
		width 18px
		float left
		margin 4px 5px 0 0

	.icon-email
		background-position -705px -183px
	
	.icon-gmail
		background-position -682px -183px

	.icon-facebook
		background-position -727px -183px

	.icon-twitter
		background-position -749px -183px

/* Slogan app s�opaulo hoje */		
.slogan-app
	background #f9f9f9
	display block
	min-height 54px
	margin-bottom 15px
	padding .5em 0 .5em 0
	
	.slogan-app-icon
		float left
		margin 0 10px 0
		width 52px
		
	.slogan-app-description
		color #666
		font-size 14px
		margin 8px 14px 0
		min-width 300px
	
	.slogan-app-btn
		background #00aeef
		box-shadow rgba(0,0,0,0.15) 0 2px 2px
		border-radius 4px
		color #fff
		display: inline-block;
		font-size 14px
		font-weight 700
		margin: 8px 5px 0 0
		padding  .45em 2.2em
		transition all .3s ease-in
		
		&:hover 
			box-shadow rgba(0,0,0,0.25) 0 2px 4px, inset rgba(255,255,255,0.25) 0 3px 1px
			text-decoration none
			
		&:active
			background #333
