.offline.success .icon,
.i-folha,
.folha-facebook i,
.folha-googleplus i,
.offline.error .icon,
.spin,
.folha-twitter i,
.folha-logo i,
.aovivo .icon-comment,
.aovivo .icon-goal-valid,
.icon-goal-against,
.loader, .offline .icon,
.share .facebook,
.share .twitter,
.share .whatsapp,
.share .google,
.share .email,
.share .link,
.share .linkedin,
.video-article i,
.gallery-article i,
.three-headline-top .lide.stars li:before,
.articleGallery a:after,
.icon_txtplus,
.icon_txtminus,
.sprite.icon-search,
.comments-article > article > header h3 a::before,
.comments-article header .more:after,
.comments-article .comment-action:after,
.comments-article .section-sprite,
.comments-article .sprite,
.item.phrase .sprite,
#bt-read-more-content:after,
.colunas-blogs .icon-filter
	background-image url(//f.i.uol.com.br/app/2/sprite-app-15b.png)