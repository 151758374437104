.preloader_content, #modal_help, #modal_base, .cover_fsp
  display none

.side-view
  overflow hidden

#main
  display block

.blast
  display inline-block
  opacity 0

.disablePanZoom
  touch-action none

.load_bar 
	width 390px
	height 6px
	background #e4e4e4
	max-width 100%
	margin 70px auto 0

.load_completed 
	height 6px
	box-shadow 0 1px 3px #f26064
	width 0
	background #f26064
	//background -moz-linear-gradient(top,#f26064 0,#a8181c 100%)
	//background -webkit-gradient(linear,left top,left bottom,color-stop(0%,#f26064),color-stop(100%,#a8181c))
	//background -webkit-linear-gradient(top,#f26064 0,#a8181c 100%)
	//background -o-linear-gradient(top,#f26064 0,#a8181c 100%)
	//background -ms-linear-gradient(top,#f26064 0,#a8181c 100%)
	background linear-gradient(to bottom,#f26064 0,#a8181c 100%)

.liveblog .article-new-item .graphic
	max-width 35%

.article-loader 
	position relative
	width 100%
	height 100%
	display none
	.loader 
		margin-top 20%

.loader 
	background-position -8px -86px
	width 62px
	height 62px
	margin 60px auto 0
	//-webkit-animation loader 1.5s linear infinite
	//-moz-animation loader 1.5s linear infinite
	animation loader 1.5s linear infinite

/*@-moz-keyframes loader 
	100% 
		-moz-transform rotate(-360deg)

@-webkit-keyframes loader 
	100% 
		-webkit-transform rotate(-360deg)
*/
@keyframes loader 
	100% 
		-webkit-transform rotate(-360deg)
		transform rotate(-360deg)

.spin 
	background-position -120px -103px
	width 30px
	height 30px
	margin 0 auto
	//-webkit-animation loader 1.5s linear infinite
	//-moz-animation loader 1.5s linear infinite
	animation loader 1.5s linear infinite
	display block

/*@-moz-keyframes loader 
	100% 
		-moz-transform rotate(-360deg)

@-webkit-keyframes loader 
	100% 
		-webkit-transform rotate(-360deg)
*/
@keyframes loader 
	100% 
		-webkit-transform rotate(-360deg)
		transform rotate(-360deg)