/* Print Edition */
.edicaoimpressa

	.txt-center
		text-align center


	.unstyled
		list-style none outside none
		padding-left 0

	.section-head
		h1, h1 a
			color #900


		.content
			width 100%
			margin auto

	.printed-edition
		margin-top 2.5rem

		@media (max-width 740px)
			margin-top 1.25rem

		.sections-list
			width 100%


		.loading
			background url(//f.i.uol.com.br/app/2/loader.png) center top no-repeat
			margin 6rem 0 0
			height 77px
			-webkit-animation spin 1s infinite linear
			animation spin 1s infinite linear

			@-webkit-keyframes spin
				to -webkit-transform rotate(360deg) 

			@keyframes spin
				to transform rotate(360deg) 


		.print
			width 100%

			@media only screen and (min-width 758px)
				float left
				width 50%
			a
				display inline-block

			img
				box-shadow 3px 1px 5px rgba(0,0,0,.3), -3px -1px 5px rgba(0,0,0,.3)
				transition all .3s ease
				width 90%

				&:hover
					box-shadow 4px 1px 5px rgba(0,0,0,.4), -4px -1px 5px rgba(0,0,0,.4)


		.edition-date
			padding-top 2rem
			width 100%
			@media only screen and (min-width 758px)
				width 45%
				float left
				margin-bottom 1.5rem
			form
				background #f1f1f1
				border-radius 10px
				padding 5%
				width 100%

				legend
					font-size 1.15rem

				select
					background #fff
					border 2px solid #ddd
					border-radius 6px
					color #666
					display block
					font-size 1.4rem
					margin-top 1rem
					outline none
					padding .5rem
					width 100%

					option:disabled
						color #ddd

			.actions
				margin-top 1rem

			button
				background #00b7f0
				border 1px solid #ddd
				border-radius 3px
				box-shadow inset rgba(0,0,0,.07) 0 3px 1px, rgba(0,0,0,.05) 0 2px
				color #fff
				font-weight 700
				padding .5rem
				text-transform uppercase
				transition all .3s ease

				&:hover, &:active
					box-shadow inset rgba(0,0,0,.06) 0 -8px 2px, rgba(0,0,0,.09) 1px 2px 2px


		/* List */
		.index-list h2,
		.section-list h3
			border-bottom-style solid
			border-bottom-width 3px
			font-family 'MetaSerifPro-Bold',Georgia,serif
			padding-bottom .8rem
			text-transform lowercase

		.index-list
			margin-top 2rem

			h2
				border-bottom-color #00b7f0
				font-size 2.1em

		.section-list
			margin 1.6rem 0

			h3
				font-size 30px

			li
				border-bottom 1px solid #ccc
				font-family Georgia,serif
				font-size 1.3em
				padding 0.6rem 0
				transition all .3s ease

				a
					color #333
				&:hover
					border-bottom-color #ddd

					a
						color #000

				.kicker
					font-size 0.7em
					font-weight 700
					margin 0
					text-transform uppercase
			
			.only_kicker
				padding-bottom .52rem

	.hide
		display none
		visibility hidden
