.folha-topicos
	.folha-topicos-title
		font-size 1.75em
		line-height 34px
		margin 30px 0 0
		text-transform uppercase

	.icon-filter
		background url(//f.i.uol.com.br/app/2/sprite-app-15b.png) no-repeat rgba(0,0,0,0)
		display inline-block
		background-position -304px -231px
		height 24px
		vertical-align top
		width 20px

	.list-square
		color #666
		list-style none outside none
		margin 16px 0 40px 0
		padding 0

		li
			background url(//f.i.uol.com.br/folha/furniture/5.4/images/bg-square.png?201401170401) no-repeat 0 6px
			font-size .875em
			margin-bottom 8px
			padding-left 12px

			a
				color #666

	.index-title
		border-bottom 2px solid #00aeef
		color #00aeef
		font-family metaserifpro-bold,Georgia,serif
		font-weight 300
		font-size 1.6em
		margin 25px 0 20px
		padding-bottom 8px
		text-transform lowercase

	.section-head
		h1
			color #036

	.item
		border-bottom 1px solid #ccc
		margin-bottom 20px
		
		.kicker
			color #999
			display inline-block
			font-family Helvetica,Arial,sans-serif
			font-weight 400
			margin-bottom 10px

		h3
			line-height 28px
			margin-bottom 10px
			font-size 1.6em
			color #222

		p
			font-family Helvetica,Arial,sans-serif
			line-height 20px
			margin-bottom 20px
			font-size 1em
			color #222

		img
			margin-bottom 14px
			width 100%
	
	.article-end
		display none
	
	// Pagination
	.pagination
		font-family Helvetica,Arial,sans-serif
		font-weight 700
		margin-bottom 50px
		padding 0
		text-align center
		
		li
			display inline-block
			padding 5px 0
			
			a
				padding 0 10px
				
				i
					font-style normal
		
		.inactive
			a
				color #ccc
				cursor default

// Tags box
.tags-related-box
	margin-bottom 40px

	.tags-related-box-title
		border-bottom 2px solid #00aeef
		color #00aeef
		font-family metaserifpro-bold,Georgia,serif
		font-weight 300
		font-size 1.6em
		padding-bottom 8px
		margin-bottom 15px
		margin-top 15px
		text-transform lowercase

	ul
		display inline-block
		margin 0
		padding 0

		li
			float left
			list-style none
			margin 0 10px 10px 0

			a
				border 1px solid #00adef
				border-radius 3px
				color #00adef
				display block
				font 700 14px/26px Arial
				padding 0 10px
				text-transform lowercase