.advertising-paywall
	z-index 9999999999
	background #efefef
	border-top 1px solid #cccccc
	width 100%
	position fixed
	bottom 0px
	color #666
	font-size 0.9em
	line-height 1.2em
	padding 10px 0

	.container 
		display inline-block
		margin 0 2%
		width 96%

		span a
			color #00b1f1
			font-weight bold

		.free-texts
			.three-star
				background url(//f.i.uol.com.br/folha/furniture/5.4/images/icn-three_stars.png) no-repeat
				display inline-block
				float left
				height 25px
				width 15%
				min-width 57px

			.paywall_warning
				width 70%
				float left
				margin 0
				text-align center
				font-weight bold

		.free-texts
		.signature
			width 100%
			text-align center

		.signature
			span
				width 40%
				float left
				margin 0 5%

	.paywall_close
		font-size 0
		position absolute
		top 10px
		right 10px
		width 13px
		height 13px
		background url(//f.i.uol.com.br/app/2/sprite-app-16.png) no-repeat
		background-position -115px -398px
		cursor pointer

@font-face
	font-family: 'folha-grafico-regular'
	src: url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-regular.eot");
	src: url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-regular.eot") format('embedded-opentype'),
		 url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-regular.woff") format('woff'),
		 url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-regular.svg#folha-grafico-regular") format('svg')

@font-face
	font-family: 'folha-grafico-medium'
	src: url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-medium.eot");
	src: url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-medium.eot") format('embedded-opentype'),
	url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-medium.woff") format('woff'),
	url("//static.folha.uol.com.br/fonts/folha-grafico/folha-grafico-medium.svg#folha-grafico-medium") format('svg')

.content-type-news
	.teaser-2-warning
		background-color #fff
		clear both
		color #333
		font-family 'folha-grafico-medium','Helvetica Neue',Helvetica,Arial,sans-serif
		font-size 16px
		line-height 1.428571429
		margin-bottom 30px
		position relative
		text-align center
		width 100%
		z-index 555

		&:before
			background linear-gradient(to top, rgba(255,255,255,1) 15%, transparent 100%)
			content ''
			display block
			height 150px
			margin-top -150px
			position absolute
			width 100%
			z-index 555

		.teaser-2-hint_header
			border solid 1px #e0e0e0
			border-top-left-radius 3px
			border-top-right-radius 3px
			border-bottom none
			display block
			font-size 1.5em
			line-height 90px
			position relative

		.teaser-2-hint_content
			border solid 1px #e0e0e0
			border-top none
			border-bottom-left-radius 3px
			border-bottom-right-radius 3px

			.teaser-2-col
				display inline-block
				width 206px

			.teaser-2-btn-login-svg
				font-weight normal
				vertical-align middle
				touch-action manipulation
				cursor pointer
				background-image none
				border 1px solid transparent
				white-space nowrap
				line-height 1.428571429
				user-select none
				text-decoration none
				display inline-block
				padding 10px 0
				width 40px
				border-width 0
				border-radius 23px
				color #fff
				font-size 16px
				margin-bottom 10px
				max-width 264px

			svg
				display block
				height 18px
				margin 0 auto

			.teaser-2-btn-login-facebook
				background-color #345094
				margin-bottom 50px

			.teaser-2-btn-login-google
				background-color #db4337
				margin-bottom 50px
				margin-left 15px

			.teaser-2-btn-login-linkedin
				background-color #0077b5
				margin-bottom 50px
				margin-left 15px

			.teaser-2-btn-uol-login
				margin-left 15px

				svg
					margin-top -54px
					height 50px

			.teaser-2-btn-login
			.teaser-2-btn-register
				background-color #fff
				border 1px solid #0078a4
				border-radius 4px
				color #0078a4
				display inline-block
				margin 16px 0 8px 0
				padding 8px 19px

				&:hover
					border-color #004b67
					color #001b25
					text-decoration none

			.teaser-2-btn-signup
				background-color #0078a4
				border 1px solid #0078a4
				border-radius 4px
				color #fff
				display inline-block
				margin 0 0 16px 0
				padding 8px 19px

				&:hover
					background-color #005371
					border-color #004b67
					text-decoration none

			.teaser-2-text-price
				font-size 24px
				line-height 1
				margin 36px 0 20px 0

				em
					font-style normal
					font-size 14px