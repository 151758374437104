.articlebookstore {
	border: 1px solid #ccc;
	border-collapse: collapse;
	border-spacing: 0 0;
	box-shadow: inset rgba(0,0,0,.03) 2px 3px 1px, rgba(0,0,0,.04) 2px 3px 1px;
	font-family: "Trebuchet MS", Arial, sans-serif;
	margin: 0 auto 20px auto;
	width: 70%;
}
.articlebookstore .sku {
	height: 0;
	line-height: 0;
	padding: 0;
	text-indent: -9999px;
}
.articlebookstore .category {
	font-size: 12px;
	font-weight: 700;
	padding: 10px 10px 0;
}
.articlebookstore .title {
	color: #444;
	font-size: 14px;
	padding: 2px 10px 0;
}
.articlebookstore .author {
	color: #777;
	font-size: 12px;
	font-weight: 700;
	padding: 2px 10px;
}
.articlebookstore .image {
	text-align: center;
}
.articlebookstore .image img {
	margin: 8px 0 0;
}
.articlebookstore .oldprice {
	color: #999;
	font-size: 14px;
	font-style: italic;
	padding: 10px 10px 2px;
	text-align: center;
}
.articlebookstore .realprice {
	font-size: 16px;
	font-weight: 700;
	padding: 0 5px;
	text-align: center;
}
.articlebookstore .buy {
	text-align: center;
}
.articlebookstore .buy a {
	background: #e10b28;
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	line-height: 15px;
	margin: 10px auto;
	padding: 2px 18px;
	text-decoration: none;
	transition: all .3s ease-in;
}
.articlebookstore .buy a:hover {
	background: #c90c28;
}