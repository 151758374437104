/*
 * Live Soccer
 *
 * @author	Anderson Felix - (a.felix) <anderson.felix@grupofolha.com.br>
 * @since	2016
 */

// Move para icons.styl
.icon-geolocalization
.icon-versus
.icone-capitao
.icone-cartao-amarelo
.icone-cartao-vermelho
.icone-gol
.icone-gol-contra
.icone-substituicao
	background-image url(//f.i.uol.com.br/app/2/sprite-app-16.png)

.icon-geolocalization
	background-position -97px -399px
	display inline-block
	height 12px
	width 8px

.icon-versus
	background-position -116px -399px
	display inline-block
	height 11px
	width 11px

.icone-capitao
	background-position -189px -435px
	display inline-block
	height 12px
	width 12px

.icone-cartao-amarelo
.icone-cartao-vermelho
	display inline-block
	height 14px
	width 10px

.icone-cartao-amarelo
	background #fc0

.icone-cartao-vermelho
	background #ed1b24

.icone-gol
.icone-gol-contra
	background-position -165px -432px
	display inline-block
	height 16px
	width 16px

.icone-gol-contra
	background-position -147px -438px
	display inline-block
	height 9px
	width 10px

.icone-substituicao
	background-position -91px -437px
	display inline-block
	height 13px
	width 16px


.live-soccer
	.live-blog-category
		margin-bottom 8px
		margin-top 0
		
		&.tab-menu
			display inline-block
			font-size 15px
			width 49%
			padding 0 1.5% 8px
			cursor pointer
			margin-bottom 3px
			border-bottom-width 0
			color #bcbcbc
			
			.icon
			
				&.icon-livefeed,
				&.icon-lineup,
				&.icon-round
					background url(//f.i.uol.com.br/folha/aovivo/images/acompanhe_off.png) 0 0 no-repeat
					width 24px
					height 24px
					padding 0 2px
					display inline-block
					vertical-align sub
					
				&.icon-lineup
					background url(//f.i.uol.com.br/folha/aovivo/images/fichadojogo_off.png) 0 0 no-repeat
				&.icon-round
					background url(//f.i.uol.com.br/folha/aovivo/images/rodada_off.png) 0 0 no-repeat
					
			&.active
				border-bottom-width 3px
				color #00aeef
				
				.icon
					&.icon-livefeed
						background url(//f.i.uol.com.br/folha/aovivo/images/acompanhe_on.png) 0 0 no-repeat
					&.icon-lineup
						background url(//f.i.uol.com.br/folha/aovivo/images/fichadojogo_on.png) 0 0 no-repeat
					&.icon-round
						background url(//f.i.uol.com.br/folha/aovivo/images/rodada_on.png) 0 0 no-repeat

	.live-blog-header
		.share
			margin-bottom 8px
			margin-top 8px

		.live-blog-info
			color #888
			float left
			font-family Helvetica, Arial, sans-serif
			font-size .85em
			line-height 1em
			// margin-bottom 50px

			.date
				color #888
				font-size inherit
				font-weight 700
				.icon-clock
					float left
					margin-left 0
					margin-right 3px
					margin-top 4px

			.live-soccer-stadium
				font-weight 700
				margin-left 5px

				.icon-geolocalization
					margin-right 3px
				.live-soccer-local
					font-weight 100

	.live-blog-news-content
		header
			margin-top 10px
			text-align center

	.live-soccer-match
		margin-top 20px

	.live-soccer-championship
	.live-soccer-phase
		font-family Helvetica, Arial, sans-serif
		font-weight 700

	.live-soccer-championship
		color #00aeef
		float none
		font-size 1.5em
		margin-bottom 8px

	.live-soccer-phase
		color #888
		float none
		font-style normal
		font-size .85em
		margin-top 0
		text-transform uppercase

	.live-soccer-match
		.live-soccer-match-team
			float left
			text-align center
			width 32%
			// outline 1px solid crimson

		.live-soccer-match-team-name
			display block
			font-family Helvetica, Arial, sans-serif
			font-size .75em
			font-weight 700
			line-height 1.2em
			margin-top 5px
			// text-align center
			text-transform uppercase

		.live-soccer-match-team-emblem
			max-width 36px

		.live-soccer-match-team.team-a
			// padding-left 8%

		.live-soccer-match-team.team-b
			// padding-right 8%

		// Score
		.live-soccer-match-score
			float left
			padding-top 7px
			width 36%
			// outline 1px dashed yellowgreen

			.live-soccer-match-score-team-a
			.live-soccer-match-score-team-b
				// display inline-block
				float left
				font-family folhaii-heavy, Georgia, serif
				width 40%

			.live-soccer-match-score-team-a
				text-align right

			.live-soccer-match-team-goals
				font-size 1.8em

			.live-soccer-match-team-penalties
				font-size .9em

			.live-soccer-match-score-versus
				// display inline-block
				float left
				font-family Helvetica, Arial, sans-serif
				text-align center
				width 20%

				.icon-versus
					margin-top 5px

			.live-soccer-status
				color #67c02c
				display block
				font-family Helvetica, Arial, sans-serif
				font-size .70em
				font-weight 700
				text-align center
				text-transform uppercase

	.live-blog-posts
		margin-top 1.5em
		
		&-lineup,&-round
			display none
		&.three
			.tab-menu
				text-align center
				width 32.3%
				.icon
					&.icon-round
						width 19px
					&.icon-livefeed,
					&.icon-lineup,
					&.icon-round
						display block
						margin 0 auto

.game-record
	color #333
	font-size 12px
	font-weight 700
	
	& &-head
		background-color #f9f9f9
		border-bottom 1px solid #a1a1a1
		font-family "folhaii-heavy", Georgia, serif
		font-size 20px
		margin-top -5px
		padding 8px 0
		text-align center
		
	&-line
		border-bottom #a1a1a1 dashed 1px
		padding 7px 5px
		line-height 1.2
		text-align center
		
	&-col
		display inline-block
		vertical-align top
		width 49.5%
		
		&.team-a
			margin-right .5%
		
		&.team-b
			margin-left .5%
		
	
	&-player
		width 100%
		padding 0
		
		&-name
			width 100%
			padding 0
			
		&-status
			vertial-align middle
		
		&-position
			color #b4b4b4
			font-size 11px
			padding 0
			height 16px
			text-transform uppercase
			vertical-align bottom
			
		&-events
			font-size 9px
			padding 0
			height 16px
			vertical-align bottom

	&-coach
		width 100%
		padding 0
		
		&-position
			color #b4b4b4
			font-size 11px
			text-transform uppercase
			
	&-bench
		margin-top 20px
		
		&-close,
		&-open
			border-top 1px solid #a1a1a1
			cursor pointer
			padding-top 5px
			text-align center
			
			&:after
				background-position -149px -16px
				content ""
				display inline-block
				height 10px
				width 20px				
			
	b
		color #b4b4b4
		font-size 11px
		text-transform uppercase
		
.team
	&-a,
	&-b
		.game-record
			&-line:last-child
				border-bottom 0 none
	
	&-a
		.game-record
			&-player
				&-status
					padding-left 4px
				
				&-name,
				&-position
					text-align right
					
				&-events
					text-align left
			
			&-coach
				text-align right

	&-b
		.game-record
			&-player
				&-status
					padding-right 4px
				&-name,
				&-position
					text-align left
					
				&-events
					text-align right
			
			&-coach
				text-align left