.erro-bg
  background #efefef
  padding 10%


.erro-title
  text-align center
  color #e71c22
  line-height 1px
  margin 10px 0
  font-weight 500

.erro-description
  text-align center