.cotidiano
        
    /*==============================
    =            Picker            =
    ==============================*/
    .picker__holder
        max-width 280px


    /*============================
    =            Icon            =
    ============================*/
    .icon
        &.star
            height 30px
            background url(//f.i.uol.com.br/star.gif) no-repeat center


    /*=============================
    =            Busca            =
    =============================*/
    .search-legend
        font 13px Arial, Helvetica, sans-serif
        color #000 
        margin-bottom 0


    /*================================
    =            Servi�os            =
    ================================*/
    .servicos
        
        &.headline h3
        &.three-headline-top h3
        .description
            color #000
        
        &.headline.curinga-header>div
        &.three-headline-top.curinga-header>div
            border-bottom none
            
        .description
            font-size 14px
            margin-top 15px
            margin-bottom 0


    /*=============================
    =            Bot�o           =
    =============================*/
    
    .btn
        width 80%
        height auto
        display block
        
        font-size 16px
        font-weight 700
        color #fff !important
        text-align center
            
        padding 12px 10px
        margin 9px auto 10px auto
        
        border none
        box-shadow 0px 4px 2px 0px #d4d4d4
                
        &:hover
            box-shadow inherit
            
    .btn-blue
        background-color #02aff7

    .link 
        font bold 14px/40px Arial, Helvetica, sans-serif
        padding 0 17px
        display inline-block
        color #00AEEF
        border 2px solid #00aeef
        border-radius 7px
        margin 10px 0
        
        .icon-chevron-right
            width 6px
            height 8px
            display inline-block
            vertical-align middle
            margin-left 10px
            background url('//f.i.uol.com.br/folha/furniture/5.4/images/icon-chevron-right-6x8.png')

    /*==============================
    =            Missas            =
    ==============================*/
    .missas
        color #000 
        padding 0
        
        .past-days
            font: 700 18px/20px Georgia, serif

            span
                text-transform: uppercase

        .item
            border-bottom: 1px solid #e0e0e0
            
            &:last-child
                border none

            p 
                font 16px/20px Georgia, Times, serif
                padding 0
                
            .title
                font bold 18px/24px Georgia, Times New Roman, serif
                margin 0
                
            .image
                margin 0 20px 0 0

        .item-highlighted
            background #F0f0f0
            border none
            padding 20px


    /*=================================
    =            Homenagem            =
    =================================*/
    .homanagem
        
        .item
            padding 20px 0
            color #000
            border-bottom 1px solid #e0e0e0

            &-highlighted
                background-color #f0f0f0
                border-bottom none
                padding 20px

                &:not(:first-of-type)
                  border-top 1px solid #e0e0e0

        .image
            display block
            float left
            margin 0 20px 20px 0

        .title
            font bold 18px/24px Georgia, Times New Roman, serif
            margin 0

        p
            font 16px/20px Georgia, Times New Roman, serif

            
    /*==================================
    =            Pagination            =
    ==================================*/
    .pagination-default
        border none
        padding 0
        margin 25px 0 0 0
        text-align center
        
        .inactive a
            color #e6e6e6
            cursor default
            
        li
            display: inline-block
            
            a
                border 1px solid #ccc
                border-radius 2px
                color #666
                display inline-block
                font-size 13px
                font-weight 700
                padding 5px 0
                transition all .2s ease-in
                width 30px

        .next a .arrow
            border-left 8px solid #666

        .prev.inactive .arrow
            border-right: 8px solid #e6e6e6

        .prev a .arrow
            border-right: 8px solid #666

        .arrow
            border-bottom 5px solid transparent
            border-top 5px solid transparent
            display inline-block
            height 0
            width 0


    /*==================================
    =            Resultados            =
    ==================================*/
    .headline.curinga-header>div.message-results
    .headline.curinga-header>div.message-info
        border-bottom none
        margin-bottom 0

    .message-results
        margin-top 10px
        font 14px/16px Helvetica, Georgia, serif
        
        span
            color #696969


    /*====================================
    =            Message info            =
    ====================================*/
    .message-info
        color #000

        h2
            font-family metaserifpro-bold,Georgia,serif
            font-weight 300
            font-size 1.6em
            border-bottom 2px solid #333
            padding-bottom 5px

        p
            font 1em/1.2 Georgia,serif
            
    /*===================================
    =            Card                   =
    ===================================*/
    .content
        hr
            margin 10px 0

        &-footer 
            font-family Georgia,Times,serif
        
            .card
                border 1px solid #000
                font-siz 14px
                margin-top 5px
                font-family Arial, Helvetica, sans-serif
                padding 20px
                
        &-2
            font 14px/18px Georgia,Times,serif
            text-align center
           
            h3
            p
            .example
                text-align left
                
            h3
                font-size 24px                
            

    /*===================================
    =             Formul�rio            =
    ===================================*/
    .form
        &#search-fester
        &#formulario-aviso-morte
        &#formulario-aviso-missa
        
            font-family Georgia, Times, serif

            fieldset
                border 0
                margin 0
                padding 0

            label
                display inline-block
                margin-bottom 5px
                line-height 23px

            label
            input
            button
            select
            textarea
                font-size 0.875em //14px
                font-weight normal

            input
            button
            select
            textarea
                border-radius 4px
                color #666
                display inline-block
                font-family "Helvetica Neue", Helvetica, Arial, sans-serif
                height 26px
                line-height 1.5
                margin-bottom 10px
                padding 4px 6px
                vertical-align middle

            input
            select
            textarea
                background-color #fff
                border 1px solid #ccc
                box-shadow inset rgba(0,0,0,0.07) 0 3px 1px
                transition border linear .2s, box-shadow linear .2s
                
            textarea
                &.form-control
                    height initial
            
            legend
                border-bottom none
                margin-bottom 0
                font-weight bold
                font-size 16px
                line-height 100%
            
            input[type="file"]
                border 0
                box-shadow initial

            .form-group
                margin-top 12px    

            .control-label
                display block
                margin-bottom 5px
                line-height 23px
                font-weight 700
                color #000

            .form-control
                width 100%
                
                &.required
                    width 93%
            
            .hide
            .hidden
                display none
                
            .help
                &-block
                    display block
                    color #555
                    font-size 12px
                    font-style italic
                    margin-top -5px
                
                &-inline
                    vertical-align top
                    font-size 18px
                    color #555
                    margin-bottom 0
                    display inline-block
                    padding-left 5px
            
            .btn
                width 80%
                height auto
                display block
                
                font-size 16px
                font-weight 700
                color #fff !important
                text-align center
                    
                padding 12px 10px
                margin 9px auto 10px auto
                
                border none
                box-shadow 0px 4px 2px 0px #d4d4d4
                        
                &:hover
                    box-shadow inherit

            .btn-blue
                background-color #02aff7
            
            .control-label
                font-weight 300
                    
            .wrap-submit 
                .btn-submit
                    width 145px
                    height 38px
                    text-align center
                    border-radius 5px
                    border none
                    color #fff
                    background-color #00aeef

                .help-inline
                    font-size 12px
                    font-style italic
                    vertical-align middle
