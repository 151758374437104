/* ################### liveblog ################ */
.liveblog,.liveblog h2 {
    float: left;
    width: 100%;
}

.liveblog h3 {
    float: left;
    width: 100%;
    margin: 10px 0 0;
    font-style: italic;
    color: #a7a7a7;
    font-size: 14px;
    letter-spacing: normal;
    font-family: arial;
    font-weight: 400;
}
.liveblog .narration{
    clear: both;
    padding-left: 0px
}
.liveblog h3 .icon-clock {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 7px;
    background: #FFF;
    border: 1px solid #999;
    position: relative;
    border-radius: 11px;
}
.liveblog h3 .icon-clock:before{
    content: '';
    width: 5px;
    height: 2px;
    background: #999;
    position: absolute;
    right: 5px;
    top: 4px;
    transform: rotate(62deg) ;
    -webkit-transform: rotate(62deg) ;
    -moz-transform: rotate(62deg) ;
    -o-transform: rotate(62deg) ;
    -ms-transform: rotate(62deg) ;
}
.liveblog h3 .icon-clock:after{
    content: '';
    width: 5px;
    height: 2px;
    background: #999;
    position: absolute;
    right: 2px;
    top: 6px;
}

.liveblog article div.highlights {
    float: left;
    width: 100%;
    color: #000;
    font-size: 22px;
    line-height: 30px;
    border: none;
    margin: 20px 0 20px;
    font-family: Georgia,serif;
    border-bottom: 1px solid #00b7f0;
    padding: 0 0 10px;
}

.liveblog article div.highlights p {
    margin: 0;
}

.article-new-list .row {
    width: 100%!important;
}

.article-new-item {
    width: 100%;
    border-bottom: 1px dotted #d2d2d2!important;
    font-size: 20px!important;
    font-family: arial!important;
    letter-spacing: normal!important;
    line-height: 26px!important;
    margin-bottom: 10px;
}

.article-new-item p{
    margin: 5px 0px 19px 0px;
}

.article-new-item:last-child {
    border: none!important;
}

.article-new-item .row {
    float: left;
    margin: 0;
}

.article-new-item .row span {
    float: left;
    padding: 0 5px;
    color: #9C9B9B;
}

.article-new-item .row .author,.article-new-item .row time {
    float: left;
    font-size: 15px!important;
    font-weight: 700;
    font-family: arial;
    color: #555;
    font-style: normal;
}

.article-new-item p a {
    color: #00b7f0;
}

.article-new-item .read-more {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #02aef0;
}

.article-new-item .graphic {
    max-width: 60%;
    float: right;
    margin-left: 20px;
}

.article-new-item p.highlights {
    color: #900;
}

.liveblog .embed-player video {
	width: 100%;
}

/* ################### final liveblog ################ */