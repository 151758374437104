#bar 
	min-height 60px
	background #ededed
	z-index 11
	width 100%
	text-align center
	top 0

	.folha-logo
		display inline-block
		margin-top 12px
		@media (max-width: 319px)
			max-width 50%
			height 52px

		img
			@media (max-width: 319px)
				height 45px
				max-width 99%
#nav 
	width 50px
	float left

	ul 
		display block
		font-family 'MetaSerifPro-Bold',folhaii-heavy,Georgia,serif
		margin 0
		padding 0
		z-index 9
		background #fff
		width 214px
		border-right 1px solid #e4e4e4
		overflow-x hidden
		text-align left


	li 
		list-style none inside
		margin: 2px 0px 0px 2px
		text-transform lowercase
		border-left 3px solid
		border-bottom 1px solid #e4e4e4
		font-size 1.3em
		
		a 
			display block
			color #444
			padding 10px 8px 17px

#nav-local 
	float left
	width 214px
	position absolute
	top 72px
	left -300px
	background #fff
	backface-visibility hidden
	z-index 500
	overflow-y scroll
	-webkit-overflow-scrolling touch
	overflow-x hidden
	height: -webkit-calc(100% - 72px)
	height: calc(100% - 72px)

#nav-toggle 
	float left
	display block
	width 50px
	height 50px
	text-indent 100%
	white-space nowrap
	overflow hidden
	z-index 1
	cursor pointer


	.toggle-nav 
		display block

	.ico-toggle
		float left
		width 18px
		height 3px
		background #666
		content ''
		position relative
		margin 21px 0 3px 15px
		transition all 0.1s cubic-bezier(0.18, 0.66, 0.41, 0.82)

		&:before, &:after
			float left
			width 18px
			height 3px
			background #666
			margin-bottom 3px
			content ''
			position absolute

		&:after
			bottom -13px
			left 0

		&:before
			top 5px
			left 0

	.stripe
		float left
		width 18px
		height 3px
		background #666
		margin 9px 0 0 15px

#extranav 
	float right
	display block

	ul
		margin 0
		padding 0


		li
			float right
			color #777
			font-size 14px
			height 50px
			min-width 45px
			list-style none inside

	.extra,.help,/#nav-toggle
		border-width 0 1px 0 0
		border-style solid
		//-webkit-border-image -webkit-gradient(linear, 0 100%, 0 0, from(#DCDCDC), to(#FFF)) 6 100%
		//-webkit-border-image -webkit-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
		//-moz-border-image -moz-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
		//-o-border-image -o-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
		border-image linear-gradient(to top, #DCDCDC, #FFF) 6 100%

	.extra
		border-width 0 0 0 1px

	.extra-toggle 
		display block
		height 60px
		width 45px
		text-indent 100%
		white-space nowrap
		overflow hidden
		position relative

		span
			display block
			width 4px
			height 4px
			background #666
			position absolute
			top 22px
			left 18px

			&:before,&:after
				content ''
				width 4px
				height 4px
				background #666
				position absolute

			&:before
				left 0
				top 6px

			&:after
				left 0
				top 12px
	#extramenu
		display none
		overflow hidden
		width 200px
		background #fff
		position absolute
		right 0
		top 71px
		box-shadow 0 0 3px 0 hsla(0,0%,0%,.13)
		margin 0
		padding 0
		z-index 500

		li
			display block
			float none
			height auto
			border-bottom 1px solid #ddd

			a
				color #444
				padding 14px
				display block
				text-align left
				font-size 16px
				text-decoration none
			
			.no-border 
				border none

		&.logged .logged_not_only,&.logged_not .logged_only 
			display none

		.controls
			height 40px

			li
				display inline-block
				width 48%
				border 0px
				height 40px

				&.separator
					border-right 1px solid #ddd

				#goback
					width 100%
					//min-width 40px
					height 40px
					padding 0 0 0 2px
					padding-left 2px
					border-width 0 1px 0 1px
					border-style solid
					//-webkit-border-image -webkit-gradient(linear, 0 100%, 0 0, from(#DCDCDC), to(#FFF)) 6 100%
					//-webkit-border-image -webkit-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
					//-moz-border-image -moz-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
					//-o-border-image -o-linear-gradient(bottom, #DCDCDC, #FFF) 6 100%
					border-image linear-gradient(to top, #DCDCDC, #FFF) 6 100%

					a
						height 40px
						width 100%
						background url(//f.i.uol.com.br/app/2/i-arrow-back.svg) 30px 7px no-repeat
						background-size 30px

			.ico-refresh
				width 100%
				height 40px
				padding 0
				background url(//f.i.uol.com.br/app/2/i-refresh.png) 37px 11px no-repeat
	
		.textsize-menu

			ul li
				display inline-block!important
				width 48%

				&.separator
					border-right 1px solid #ddd

			.less, .plus
				text-align center

			.icon_txtplus
				background-position -233px -229px

			.icon_txtminus
				background-position -268px -230px

			.icon_txtplus,.icon_txtminus
				width 25px
				height 20px
				display block
				margin auto

	li.aovivo-update 
		width 0px
		min-width 0!important
		height 0

	.no-border-menu
		border-style none !important

.tooltip
	background #fff
	position absolute
	top 100px
	right -4px
	border 1px solid #bbb
	border-radius 2px
	box-shadow 0 0 5px #D8D8D8
	text-align center


	a
		color #999
		font-weight 600
		padding 6px 10px 5px
		display block


	&:before
		border solid
		border-color #777 transparent
		border-width 0 6px 6px
		top -7px
		content ""
		right 94px
		position absolute
		z-index 10


#tooltip-classica
	display none
	width 210px
