/* Base colors */
$colorColunistas = #900 //Arquivo, Blogs, Colunistas, Edi��o digital, S�o paulo, Servi�os, �ltimas
$colorMundo = #036 //Mundo, Poder
$colorMercado = #063 //Mercado
$colorCotidiano = #603 //Cotidiano, Saber
$colorEsporte =  #f90 //Esporte, ao vivo
$colorIlustrada = #903 //Ilustrada
$colorCiencia = #603 //Ci�ncia
$colorFolhinha = #00adee //Classificados, Equilibrio, Equilibrio e sa�de, Folhinha, Sa�ude, Tec, Turismo, F5
$colorClassificados =  #ed743f //Classificados
$colorAmbiente = #008500 //Ambiente
$colorComida = #745445 //Comida
$colorIlustrissima =  #ae2f3e //Ilustrissima
$colorNYT = #bdc0c0 //New York Times
$colorEuronews = #000 //Euronews
$colorF5 = #00adee //F5


.nav-capa, .nav-opiniao, .nav-colunistas, .nav-especial, .nav-saopaulo, .color-opiniao, .nav-fsp
	border-left-color $colorColunistas
	color $colorColunistas

.color-mundo, .color-poder, .nav-mundo, .nav-poder
	color $colorMundo
	border-left-color $colorMundo

.color-mercado, .nav-mercado
	color $colorMercado
	border-left-color $colorMercado

.color-cotidiano, .color-saber, .nav-cotidiano, .nav-saber
	color $colorCotidiano
	border-left-color $colorCotidiano

.color-esporte, .color-aovivo, .nav-esporte, .nav-rio-2016, .nav-aovivo
	color $colorEsporte
	border-left-color $colorEsporte

.color-ilustrada, .nav-ilustrada
	color $colorIlustrada
	border-left-color $colorIlustrada

.color-ciencia, .nav-ciencia
	color $colorCiencia
	border-left-color $colorCiencia

.color-equilibrio, .color-equilibrio-e-saude, .color-folhinha, .color-saude, .color-tec, .color-turismo, .color-f5, .nav-equilibrio,.nav-folhinha, .nav-saude, .nav-tec, .nav-turismo, .nav-ultimasnoticias, .nav-tvfolha, .nav-fotografia, .nav-bbc, .nav-vice, .nav-empreendedor-social, .nav-edicaoimpressa, .nav-f5
	color $colorFolhinha
	border-left-color $colorFolhinha

.color-classificados, .nav-classificados
	color $colorClassificados
	border-left-color $colorClassificados

.color-ambiente, .nav-ambiente
	color $colorAmbiente
	border-left-color $colorAmbiente

.color-comida, .nav-comida
	color $colorComida
	border-left-color $colorComida

.color-ilustrissima, .nav-ilustrissima
	color $colorIlustrissima
	border-left-color $colorIlustrissima

.nav-newyorktimes
	border-left-color $colorNYT
	color $colorNYT

.nav-euronews
	border-left-color $colorEuronews
	color $colorEuronews

.nav-serafina
	border-left-color #000
	color #000

.caramel li.azul
	background-color #006996

.azul.claro, .caramel li.azul.claro, .azul-claro, .caramel li.azul-claro
	background-color #009ee0

.azul.escuro, .caramel li.azul.escuro, .azul-escuro, .caramel li.azul-escuro
	background-color #003576

.laranja, .caramel li.laranja
	background-color #f29400

.laranja.claro, .caramel li.laranja.claro, .laranja-claro, .caramel li.laranja-claro
	background-color #f0b75a

.laranja.escuro, .caramel li.laranja.escuro, .laranja-escuro, .caramel li.laranja-escuro
	background-color #cb6418

.verde, .caramel li.verde
	background-color #007e72

.verde.claro, .caramel li.verde.claro, .verde-claro, .caramel li.verde-claro
	background-color #0098a1

.verde.escuro, .caramel li.verde.escuro, .verde-escuro, .caramel li.verde-escuro
	background-color #005F5B

.caramel li.vermelho
	background-color #c30324

.vermelho.claro, .caramel li.vermelho.claro, .vermelho-claro, .caramel li.vermelho-claro
	background-color #eb6b4a

.vermelho.escuro, .caramel li.vermelho.escuro, .vermelho-escuro, .caramel li.vermelho-escuro
	background-color #9e0923

.meta h5 p, .meta .azul, .blue, .color-colunistas
	color #00aeef

.meta .vermelho, .red, .lide li strong.red
	color #FF0000

.lightblue
	color #68B3D6

.nav-list li.azul
	color #00adee
	border-left-color #00adee
	background-color transparent

.nav-list li.azul-escuro
	color #036
	border-left-color #036
	background-color transparent

.nav-list li.laranja
	color #f90
	border-left-color #f90
	background-color transparent

.nav-list li.laranja-escuro
	color #ed743f
	border-left-color #ed743f
	background-color transparent

.nav-list li.marrom
	color #745445
	border-left-color #745445
	background-color transparent

.nav-list li.verde
	color #008500
	border-left-color #008500
	background-color transparent

.nav-list li.verde-escuro
	color #063
	border-left-color #063
	background-color transparent

.nav-list li.vermelho
	color #900
	border-left-color #900
	background-color transparent

.nav-list li.vermelho-escuro
	color #903
	border-left-color #903
	background-color transparent

.nav-list li.cinza
	color #bdc0c0 
	border-left-color #bdc0c0
	background-color transparent 

.nav-list li.preto
	color #000
	border-left-color #000
	background-color transparent

/*
* Cores do caramelo caso cor n�o for definida no admin
* Cores do nome da editoria
*/
.arquivo, .blogs, .colunistas, .edicaodigital, .especial, .opiniao, .capa, .saopaulo, .ultimasnoticias, .fsp
	.section-head h1
		color $colorColunistas

.folhinha, .mundo, .poder, .folhinha
	.section-head h1
		color $colorMundo

.mercado, .ambiente
	.section-head h1
		color $colorMercado

.cotidiano, .saber, .educacao
	.section-head h1
		color $colorCotidiano

.esporte, .aovivo
	.section-head h1
		color $colorEsporte

.ilustrada
	.section-head h1
		color $colorIlustrada

.f5 
	.section-head h1
		color $colorF5

.ciencia
	.section-head h1
		color $colorCiencia
		
.classificados
	.section-head h1
		color $colorClassificados

.equilibrio, .equilibrioesaude, .folhinha, .saude, .tec, .turismo, .erramos, .multimidia
	.section-head h1
		color $colorFolhinha

.ambiente
	.section-head h1
		color $colorAmbiente

.comida
	.section-head h1
		color $colorComida

.ilustrissima
	.section-head h1
		color $colorIlustrissima

		
/*
* Cabe�alhos das caixas de editorias
*/

.box-header, .headline.curinga-header .box-header
	&.mundo, &.poder, &.poder a
		color $colorMundo
		.box-title
			border-bottom-color $colorMundo
			
	&.mercado, &.ambiente, &.ambiente a
		color $colorMercado
		.box-title
			border-bottom-color $colorMercado

	&.cotidiano, &.cotidiano a, &.saber, &.saber a, &.educacao, &.educacao a
		color $colorCotidiano
		.box-title
			border-bottom-color $colorCotidiano

	&.esporte, &.esporte a, &.aovivo, &.aovivo a
		color $colorEsporte
		.box-title
			border-bottom-color $colorEsporte

	&.ilustrada, &.ilustrada a
		color $colorIlustrada
		.box-title
			border-bottom-color $colorIlustrada

	&.f5, .f5 a
		color $colorF5
		.box-title
			border-bottom-color $colorF5

	&.ciencia, &.ciencia a
		color $colorCiencia
		.box-title
			border-bottom-color $colorCiencia

	&.equilibrio, &.equilibrio a, &.equilibrioesaude, &.equilibrioesaude a, &.folhinha, &.folhinha a, &.saude, &.saude a, &.tec, &.tec a, &.turismo, &.turismo a, &.erramos, &.erramos a, &.multimidia, &.multimidia a
		color $colorFolhinha
		.box-title
			border-bottom-color $colorFolhinha
			
	&.classificados, &.classificados a 
		color $colorClassificados
		.box-title
			border-bottom-color $colorClassificados

	&.ambiente, &.ambiente a
		color $colorAmbiente
		.box-title
			border-bottom-color	colorAmbiente

	&.comida, &.comida a
		color $colorComida
		.box-title
			border-bottom-color $colorComida

	&.ilustrissima, &.ilustrissima a
		color $colorIlustrissima
		.box-title
			border-bottom-color $colorIlustrissima

	&.saopaulo, &.saopaulo a
		color $colorColunistas
		.box-title
			border-bottom-color $colorColunistas

/*
* Cores da barra de fundo da editoria
*/
.equilibrio ,.equilibrioesaude ,.folhinha ,.saude ,.tec ,.turismo, .erramos, .multimidia
	.section-head section
		background-color #c1cd00

.classificados,.comida
	.section-head section
		background-color #fbbd56

.newyorktimes
	.section-head section
		background-color $colorNYT

.euronews
	.section-head section
		background-color $colorEuronews

.serafina
	.section-head section
		background-color #000

.tvfolha, .asmais
	.section-head section
		background-color #e5e5e5

/*
* Cabe�alhos de editorias customizados
*/
.bbc,.vice, .guiadelivrosfilmesdiscos, .rfi, .dw, .agora, .fapesp
	.section-head
		h1
			overflow hidden
			color transparent
			white-space nowrap
			width 50%
			top 0
			left 0

.bbc
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-bbc-3.png?2015) left no-repeat
		height 34px

.vice
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-vice-3.png?2015) top left no-repeat
		height 52px
		background-size auto 34px

.euronews
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-euronews.png?2015) top left no-repeat
		height 34px
		color transparent
		white-space nowrap
		width 100%
		overflow hidden
		bottom 0
		left 10px

.agora
	.section-head h1
		background url(//f.i.uol.com.br/app/2/logo-agora-92x34.png) top left no-repeat
		height 34px
		
.fapesp
	.section-head h1
		background url(//f.i.uol.com.br/app/2/logo-fapesp-mobile-137x34.png) top left no-repeat
		height 34px

.guiadelivrosfilmesdiscos 
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-guia-livros.png?2015) top left no-repeat
		height 52px
		background-size auto 50px
		
.newyorktimes
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-nyt-2.png) top left no-repeat
		height 34px
		color transparent
		white-space nowrap
		width 100%
		overflow hidden
		bottom 0
		left 10px

.serafina
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-serafina-d.png) bottom left no-repeat
		height 47px
		color transparent
		white-space nowrap
		width 50%
		overflow hidden
		bottom 0
		left 10px
			

.rfi
	.section-head h1
		background url(//f.i.uol.com.br/folha/furniture/5.4/images/trj-rfi-35x35.png?2015) left no-repeat
		height 34px

.dw
	.section-head h1
		background url(//f.i.uol.com.br/app/2/title-dw.png) left no-repeat
		height 34px
		background-size auto 34px