.box
    &.placar
        border-bottom 1px solid #00adef
        border-top 1px solid #00adef
        margin 10px 0
        padding 5px 0
        
        .kicker
            color #303030
            display block
            font-size 14px
            font-weight 700
            line-height 18px
            text-transform uppercase
            
        .anchor
            text-align right
            line-height 12px
            display inline-block
            width 51.4%
                
            a
                font-size 12px
                color #00adef  
            
        .result-content
            margin-top 5px
            
            &-pro,
            &-against
                width 33.5%
                display inline-block
                text-align left
                
                .counter
                    font-size 28px
                    font-weight bold
                    color #006684
                    line-height 1em
                    display block
                    
                 .text
                    color #303030
                    display block
                    font-size 12px
                    font-weight 700
                    line-height 14px
                    text-transform uppercase
                    text-align left
                    
            &-against
                text-align right

                .counter
                    color #c42828
                    
                .text
                    text-align right
                    
            &-missed
                color #777
                font-size 11px
                margin 4px 0 0
                line-height 1em
                display inline-block
                width 46.4%    
                max-width 195px            
                
                .legend-missed
                    width 11px
                    height 11px
                    background-color #ccc
                    display inline-block
                    margin-right 5px

                .counter
                    display inline-block
                
            &-text
                padding 5 20px
                padding-bottom 0
                padding-left 2px
                color #000
                font-size 12px
                line-height 1.1em
                text-align left
                
            &-total-missing
                text-align center
                font-size 14px
                font-weight bold
                text-transform uppercase
                color #00adef
                border #ccc 1px solid
                border-left 0
                border-right 0
                padding 10px 0
                
                .counter
                    display inline-block
                
            &-image
                width 28.6%
                height 50px
                display inline-block
                vertical-align bottom
                text-align center
                z-index -1
                                
                img
                    height 100%
                    display none
                    
            &-bar
                height 15px
                width 100%
                position relative
                
                &-pro,
                &-against,
                &-missed
                    margin 0
                    padding 0
                    float left
                    height 100%
                    display inline-block
                
                &-pro
                   background #006684
                
                &-against
                    background #c42828
                
                &-missed
                    background #ccc
                    
                &-limiter
                    position absolute
                    left 66%
                    bottom 0
                    width 2px
                    height 25px
                    background #000
                    
                    .text
                        width 128px
                        height auto
                        position absolute
                        top -20px
                        left -64px 
                        max-width 128px
                        font-size 11px
                        text-align center
                        color #303030
                        line-height 12px

        &-senado
            .result-content
                &-bar
                    &-limiter
                        left 50%
                        background-color transparent
                        
                        .text
                            width 120px
                            top -30px
                            left -60px
                        
        &-final-senado
            .result-content
                &-bar
                    &-limiter
                        left 66.66%
                        
        &[class*="senado"]
            .kicker
                font-size 13px
            