.contest
  padding-bottom 10px
  nav
    background-color #00afc3
    display block
    position relative
    a
      color #fff
      display block
      font-size 15px
      font-style normal
      letter-spacing 0.15em
      line-height 18px
      padding 19px 0
      padding-left 17px
      padding-top 18px
      vertical-align middle
      text-align center
      text-transform uppercase
    .banner-88x31
      max-width 88px
      position absolute
      top 12px
      right 17px
      max-height 31px
    &.c-left
      a
        display inline-block
        padding-left 17px
        padding-top 17px

  .medals
    width 100%
    thead
      th
        border-bottom 1px solid #cfcdb4
        height 56px
        width 30px
        vertical-align middle
    tbody
      tr
        &:nth-of-type(odd)
            background-color #f9f9f9
      td
        height 40px
        text-align center
        vertical-align middle

    td
      &:not(:nth-child(2))
        padding 0 7.5px
        width 10px
      &:first-child
        padding 0
        width 40px
        &.text-center
          text-align center
    .item
      background-repeat no-repeat
      background-size 100%
      display block
      height 21px
      margin 0 auto
      position relative
      width 25px
    .kicker
      padding-left 10px
      position static
      background inherit
      color #7b7a6c
      font-size 15px
      line-height 18px
      text-transform uppercase
      margin-right 5px
      margin-top 20px
    .more-results
      float right
      &:after
        background url("//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/svg/arrow45_20px.svg?2016031101")
        content ''
        display block
        float left
        height 13px
        margin-right 6px
        margin-top 2.5px
        position relative
        width 13px
    .active
      background-color #f4feff
    .country-icon
      min-width inherit
      margin-right 7px
      vertical-align sub
      width 22px
    .gold-medal
      background-image url("//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/medals/ouro_desk.png")
    .silver-medal
      background-image url("//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/medals/prata_desk.png")
    .bronze-medal
      background-image url("//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/medals/bronze_desk.png")
    .all-medals
      background url("//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/medals/total_desk.png")
      background-repeat no-repeat
      background-size 100%
      display block
      float right
      margin-top 2px
      height 25px
      width 37px
  

.contest .medals td:first-child, .contest .medals td:nth-child(2)
  text-align left