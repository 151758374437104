/* Especific OS atributes -
Webkit deve conter o translate3d para ativar a placa gr�fica no navegador. Caso n�o utilizar o conte�do vai ficar em branco (aparece no DOM mas n�o � renderizado).
Caso translate3d quebre layout (pq ele ignora overflow hidden) utiliza translateZ.
Para Android, mesmo que seja webkit, n�o utilizar pois cria um delay de renderiza��o, e sem necessidade pois no android n�o fica em branco.
Isso acontece pois utilizamos o iScroll nos swipes. */

.ios #pagination>li,.ios i,.ios iframe,.ios img,.ios video
	-webkit-transform translate3d(0,0,0)


.ios .articleGallery,.ios .share ul,.ios .text_size ul
	-webkit-transform translateZ(0)


.ios .content-type-news header
	-webkit-transform translateZ(0)
	z-index 1


.blackberry #pagination .share ul,.blackberry #pagination .text_size ul,.blackberry i, .blackberry img
	-webkit-transform translate3d(0,0,0)


.blackberry .content-type-news header
	-webkit-transform translateZ(0)


.aovivo .content-type-service header,.aovivo .escalation .graphic-position
	-webkit-transform scale(1)


.aovivo .escalation
	-webkit-transform translateZ(0)
