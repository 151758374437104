.empreendedorsocial
        
    figure
        margin 0
        
        img
            display block
            width 100%

    /*===================================
    =             Formul�rio            =
    ===================================*/
    .form
        @extend .cotidiano .form#formulario-aviso-morte

        span.error
            color #b94a48
            display inline-block
            font-size 12px
            font-style italic
            
    // Patrocinadores
    .sponsors
        ul
            margin 0
            padding 0

            li ul li
                display inline-block
                width 40%
                text-align center
                
                a
                    display inline-block
                    
                    img
                        max-width 90%