//Fix para Opinião
.content-type-section.opiniao
	.special-fsp .headline
		border 0

	img
		max-width 100%
		margin auto
		display block

	.group-title
		border-bottom 2px solid #00aeef
		font-size 1.7em
		margin-bottom 10px
		margin-top 20px
		font-family 'MetaSerifPro-Bold',Georgia,serif

	.paineldoleitor,.erramos
		display block
		background #00aeef
		padding 12px
		font-size 1.2em

		a
			color #fff
			text-decoration none
	.foto
		text-align center
		margin-bottom 10px

		a
			display block

	.block-left
		margin-top 20px

	.ads-three-columns.ad-opiniao
		margin-top 15px;