.lide
	display block
	//font-size 16px
	padding 0

	li
		list-style square inside
		padding-left 3px
		display list-item
		margin-bottom 5px
		margin-top 10px


		a
			font-size 0.9em


		strong
			color #00adef
			text-transform uppercase


.headline .lide
	padding-top 0px

.with-lide
	border-bottom 0 none
	padding-bottom 0
	margin-bottom 10px