.aovivo ul{
    padding-left: 0px;
}

.aovivo figure{
    margin: 0;
}

/* ################### Ao vivo na Copa ################### */
/* icons */
.aovivo .icon-card-yellow {
    display: inline-block;
    width: 18px;
    height: 26px;
    background: #fc0;
}

.aovivo .icon-card-red {
    display: inline-block;
    width: 18px;
    height: 26px;
    background: #ed1b24;
}

.aovivo .icon-goal-valid {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-position: -113px -50px;
}

.aovivo .icon-goal-against {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-position: -62px -51px;
}

.aovivo .icon-comment {
    display: inline-block;
    width: 30px;
    height: 26px;
    background-position: -10px -49px;
}

.aovivo .icon-substitution-out {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ed1b24;
}

.aovivo .icon-substitution-in {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #00b004;
}

.aovivo .icon-captain {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #000;
    color: #FFF;
    text-transform: uppercase;
    border-radius: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
    text-indent: 0;
    line-height: 24px;
    font-style: normal;
    font-size: 20px;
}
/* final icons */


/* confronto */
.aovivo .confrontation {
    float: left;
    width: 100%;
}

.aovivo .confrontation h2 {
    color: #02b7ee;
    border-bottom: 3px solid #02b7ee;
}

.confrontation .date-starting {
    float: left;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    box-sizing: border-box;
    padding: 0 0 6px 3px;
    color: #666;
    font-size: 14px;
}

.confrontation .datetime,.local,.stage {
    float: left;
    width: 100%;
}

.confrontation .datetime {
    font-weight: 700;
}
/* final confronto */


/* placar */
.scoreboard {
    float: left;
    width: 100%;
    margin-bottom: 6px;
    color: #666;
}

.scoreboard .list-football {
    float: left;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
}

.list-football .list-football-item {
    float: left;
    width: 33%;
    font-family: 'MetaSerifPro-Bold';
    border: none;
    vertical-align: top;
    position: relative;
}

.list-football-item .team-name {
    float: left;
    width: 100%;
    font-size: 21px;
    text-align: center;
    padding: 20px 0 0;
    font-weight: 700;
    word-wrap: break-word;
}

.list-football-item .team-name .align-middle {
    display: table-cell;
    box-sizing: border-box;
    width: 160px;
    padding: 0 20px 0 0;
    height: 63px;
    vertical-align: middle;
}

.list-football-item .flag {
    float: left;
    width: 100%;
    text-align: center;
}

.list-football .list-football-item.number-scoreboard {
    display: inline-block;
    border: none;
    line-height: normal!important;
    vertical-align: top;
}

.number-scoreboard .number-item {
    display: inline-block;
    width: 22%;
    font-family: arial;
    font-size: 68px;
    text-align: left;
}

.number-scoreboard .number-item strong {
    float: left;
    width: 100%;
}

.number-scoreboard .commander {
    text-align: right;
}

.number-scoreboard .versus {
    display: inline-block;
    width: 75px;
    font-family: arial;
    vertical-align: top;
}
/* final placar */


/* menu */
.aovivo .confrontation h2 {
    font-size: 1.8em;
}

.number-scoreboard .versus strong {
    float: left;
    width: 75px;
    text-align: center;
    font-size: 55px;
}

.number-scoreboard .transmission {
    float: left;
    width: 100%;
    text-align: center;
    color: #fbae2a;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    bottom: 0px;
    left: 0;
    font-family: arial;
}

.aovivo-update #tooltip-aovivo-update {
    width: 205px;
    position: absolute;
    top: 55px;
    left: 50%;
    margin-left: -103px;
    right: auto;
}


.aovivo .menu .list-menu {
    float: left;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
}



.aovivo .menu .list-menu li {
    display: inline-block;
    float: left;
    width: 33.3%;
    border: none;
    font-size: 18px;
    color: #cfcfcf;
    font-weight: 700;
    font-family: arial;
    margin: 0;
    border-left: 1px solid #f2f2f2;
    letter-spacing: 0;
    padding: 0;
}

.aovivo .menu .list-menu li:first-child,.list-menu li:first-child {
    border: none;
}



.aovivo .menu .list-menu li a {
    float: left;
    width: 100%;
    padding: 10px 0;
    color: #cfcfcf;
    -webkit-tap-highlight-color: transparent;
}



.aovivo .menu .list-menu li.active {
    color: #ffae2b;
}



.aovivo .menu .list-menu li.active a {
    color: #ffae2b;
}

.aovivo .menu h3.title{
    float: left;
    width: 100%;
    color: #ffae2b;
    font-size: 18px;
    font-family: arial;
    font-weight: 700;
    text-align: center;
    padding: 10px 0;
    background: #fafafa;
    letter-spacing: 0;
    display: none;
}
/* final menu */


/* posição do time */
.aovivo .interaction,.item-interaction,.list-interaction {
    float: left;
    width: 100%;
}

.aovivo .interaction {
    padding: 30px 0;
}

.aovivo .item-interaction .icon-card-red,.aovivo .item-interaction .icon-card-yellow {
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 21px;
}

.aovivo .item-interaction .icon-goal-against,.aovivo .item-interaction .icon-goal-valid {
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: 14px;
}

.aovivo .item-interaction .icon-comment {
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: 11px;
}

.aovivo .item-interaction .icon-captain {
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: 15px;
}

.aovivo .item-interaction .icon-substitution-out {
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    margin-left: 19px;
}

.aovivo .item-interaction .icon-substitution-in {
    display: table-cell;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    margin-left: 19px;
}

.aovivo .item-interaction {
    float: left;
    width: 100%;
    font-size: 17px;
    color: #656565;
    font-family: arial;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    margin: 0;
    padding: 10px 0;
}

.aovivo .item-interaction p {
    float: left;
    width: auto;
    padding: 3px 0 3px 13px;
    margin: 0 0 0 56px;
    border-left: 1px solid #ccc;
}

.aovivo .team-position {
    display: none;
}

.aovivo .interaction {
    float: left;
    width: 100%;
}

.aovivo .escalation {
    float: left;
    width: 100%;
    display: none;
}

/* final posição do time */


/* campo */
.aovivo .escalation .graphic-position {
    display: block;
    width: 100%;
    max-width: 455px;
    height: 232px;
    margin: 0 auto;
    padding: 30px 0;
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/campo.png) center no-repeat;
    background-size: 100% auto;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    z-index: 1;
}
/* final campo */

/* uniformes */
.aovivo .graphic-position .uniform {
    float: left;
    width: 25px;
    height: 30px;
    position: absolute;
    text-align: center;
    font-weight: 700;
    line-height: 30px;
    font-size: 13px;
}

.aovivo .graphic-position .uniform:after {
    float: left;
    width: 74%;
    height: 43px;
    background: -webkit-radial-gradient(50% 50%,circle cover,rgba(0,0,0,.4),rgba(0,0,0,.1) 40%,rgba(0,0,0,0) 50%);
    margin-left: 10%;
    margin-top: -2px;
    content: "";
    -webkit-transform: rotateX(73deg) translateZ(21px);
    -moz-transform: rotateX(73deg) translateZ(21px);
    -ms-transform: rotateX(73deg) translateZ(21px);
    -o-transform: rotateX(90deg) translateZ(-150px);
    transform: rotateX(73deg) translateZ(21px);
}

.aovivo .graphic-position .uniform .icon-captain {
    float: none;
    border-radius: 7px;
    width: 10px;
    height: 10px;
    line-height: 11px;
    font-size: 8px;
    position: absolute;
    top: 0;
    right: -2px;
    padding: 0;
    z-index: 1;
    -webkit-text-size-adjust: 85%;
}

.graphic-position.yellow-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.yellow-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.yellow-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.yellow-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.yellow-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.yellow-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.yellow-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.yellow-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.yellow-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.yellow-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.yellow-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.yellow-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.yellow-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.yellow-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.yellow-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.yellow-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.blue-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.blue-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.blue-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.blue-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.blue-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.blue-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.blue-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.blue-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.blue-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.blue-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.blue-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.blue-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.blue-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.blue-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.blue-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.blue-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.white-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.white-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.white-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.white-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.white-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.white-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.white-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.white-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.white-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.white-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.white-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.white-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.white-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.white-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.white-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/branco-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.white-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.gray-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.gray-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.gray-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.gray-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.gray-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.gray-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.gray-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.gray-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.gray-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.gray-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.gray-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.gray-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.gray-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.gray-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.gray-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.gray-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.orange-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.orange-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.orange-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.orange-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.orange-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.orange-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.orange-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.orange-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.orange-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.orange-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.orange-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.orange-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.orange-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.orange-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.orange-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/laranja-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.orange-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.black-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.black-yellow .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/amarelo-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.black-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.black-blue .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/azul-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.black-white .black,.graphic-position.black-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.black-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.black-gray .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/cinza-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.black-orange .black,.graphic-position.black-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.black-black .black,.graphic-position.black-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.black-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.black-green .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.black-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.black-red .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.green-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.green-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.green-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.green-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.green-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.green-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.green-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.green-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.green-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.green-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.green-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.green-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.green-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-verde.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.green-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.green-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/verde-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.green-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.red-yellow span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.red-yellow .yellow {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-amarelo.png) center no-repeat;
    background-size: 100% auto;
    color: #ff0;
}

.graphic-position.red-blue span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.red-blue .blue {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-azul.png) center no-repeat;
    background-size: 100% auto;
    color: #00f;
}

.graphic-position.red-white span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.red-white .white {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-branco.png) center no-repeat;
    background-size: 100% auto;
    color: #fff;
}

.graphic-position.red-gray span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.red-gray .gray {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-cinza.png) center no-repeat;
    background-size: 100% auto;
    color: #a9a9a9;
}

.graphic-position.red-orange span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.red-orange .orange {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-laranja.png) center no-repeat;
    background-size: 100% auto;
    color: #f90;
}

.graphic-position.red-black span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-preto.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.red-black .black {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-preto.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}

.graphic-position.red-green span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.red-green .green {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-verde.png) center no-repeat;
    background-size: 100% auto;
    color: green;
}

.graphic-position.red-red span {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/vermelho-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: #000;
}

.graphic-position.red-red .red {
    background: url(//f.i.uol.com.br/folha/aovivo/esporte/futebol/camisetas/preto-vermelho.png) center no-repeat;
    background-size: 100% auto;
    color: red;
}
/* final uniformes */


/* posições max 480 - posição esquerda do campo */
.team-1 .formation-3-4-3 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-3-4-3 .a2 {
    left: 115px;
    bottom: 95px;
}

.team-1 .formation-3-4-3 .a3 {
    left: 127px;
    top: 60px;
}

.team-1 .formation-3-4-3 .a4 {
    left: 137px;
    top: 20px;
}

.team-1 .formation-3-4-3 .a5 {
    left: 212px;
    bottom: 115px;
}

.team-1 .formation-3-4-3 .a6 {
    left: 211px;
    top: 37px;
}

.team-1 .formation-3-4-3 .a7 {
    left: 255px;
    bottom: 79px;
}

.team-1 .formation-3-4-3 .a8 {
    left: 247px;
    top: 15px;
}

.team-1 .formation-3-4-3 .a9 {
    left: 320px;
    bottom: 88px;
}

.team-1 .formation-3-4-3 .a10 {
    right: 136px;
    top: 21px;
}

.team-1 .formation-3-4-3 .a11 {
    right: 99px;
    bottom: 143px;
}

.team-1 .formation-3-5-2 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-3-5-2 .a2 {
    left: 115px;
    bottom: 95px;
}

.team-1 .formation-3-5-2 .a3 {
    left: 127px;
    top: 60px;
}

.team-1 .formation-3-5-2 .a4 {
    left: 137px;
    top: 20px;
}

.team-1 .formation-3-5-2 .a5 {
    left: 190px;
    bottom: 139px;
}

.team-1 .formation-3-5-2 .a6 {
    left: 225px;
    bottom: 106px;
}

.team-1 .formation-3-5-2 .a7 {
    left: 224px;
    top: 35px;
}

.team-1 .formation-3-5-2 .a8 {
    left: 283px;
    bottom: 75px;
}

.team-1 .formation-3-5-2 .a9 {
    left: 268px;
    top: 18px;
}

.team-1 .formation-3-5-2 .a10 {
    right: 94px;
    bottom: 107px;
}

.team-1 .formation-3-5-2 .a11 {
    right: 116px;
    bottom: 163px;
}

.team-1 .formation-4-3-3 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-4-3-3 .a2 {
    left: 160px;
    bottom: 72px;
}

.team-1 .formation-4-3-3 .a3 {
    left: 118px;
    top: 93px;
}

.team-1 .formation-4-3-3 .a4 {
    left: 133px;
    top: 40px;
}

.team-1 .formation-4-3-3 .a5 {
    left: 172px;
    top: 14px;
}

.team-1 .formation-4-3-3 .a6 {
    left: 265px;
    bottom: 83px;
}

.team-1 .formation-4-3-3 .a7 {
    left: 214px;
    top: 56px;
}

.team-1 .formation-4-3-3 .a8 {
    left: 257px;
    top: 23px;
}

.team-1 .formation-4-3-3 .a9 {
    left: 326px;
    bottom: 77px;
}

.team-1 .formation-4-3-3 .a10 {
    right: 139px;
    top: 18px;
}

.team-1 .formation-4-3-3 .a11 {
    right: 101px;
    bottom: 144px;
}

.team-1 .formation-4-4-2 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-4-4-2 .a2 {
    left: 102px;
    bottom: 72px;
}

.team-1 .formation-4-4-2 .a3 {
    left: 112px;
    top: 93px;
}

.team-1 .formation-4-4-2 .a4 {
    left: 125px;
    top: 52px;
}

.team-1 .formation-4-4-2 .a5 {
    left: 136px;
    top: 14px;
}

.team-1 .formation-4-4-2 .a6 {
    left: 184px;
    bottom: 114px;
}

.team-1 .formation-4-4-2 .a7 {
    left: 188px;
    top: 42px;
}

.team-1 .formation-4-4-2 .a8 {
    left: 235px;
    bottom: 114px;
}

.team-1 .formation-4-4-2 .a9 {
    left: 234px;
    top: 43px;
}

.team-1 .formation-4-4-2 .a10 {
    right: 132px;
    top: 42px;
}

.team-1 .formation-4-4-2 .a11 {
    right: 124px;
    bottom: 123px;
}

.team-1 .formation-4-5-1 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-4-5-1 .a2 {
    left: 140px;
    bottom: 72px;
}

.team-1 .formation-4-5-1 .a3 {
    left: 119px;
    top: 93px;
}

.team-1 .formation-4-5-1 .a4 {
    left: 129px;
    top: 52px;
}

.team-1 .formation-4-5-1 .a5 {
    left: 158px;
    top: 14px;
}

.team-1 .formation-4-5-1 .a6 {
    left: 287px;
    bottom: 83px;
}

.team-1 .formation-4-5-1 .a7 {
    left: 225px;
    bottom: 115px;
}

.team-1 .formation-4-5-1 .a8 {
    left: 190px;
    bottom: 142px;
}

.team-1 .formation-4-5-1 .a9 {
    left: 225px;
    top: 34px;
}

.team-1 .formation-4-5-1 .a10 {
    right: 177px;
    top: 20px;
}

.team-1 .formation-4-5-1 .a11 {
    right: 115px;
    bottom: 145px;
}

.team-1 .formation-5-4-1 .a1 {
    left: 73px;
    top: 60px;
}

.team-1 .formation-5-4-1 .a2 {
    left: 151px;
    bottom: 79px;
}

.team-1 .formation-5-4-1 .a3 {
    left: 126px;
    top: 93px;
}

.team-1 .formation-5-4-1 .a4 {
    left: 114px;
    top: 59px;
}

.team-1 .formation-5-4-1 .a5 {
    left: 140px;
    top: 39px;
}

.team-1 .formation-5-4-1 .a6 {
    left: 165px;
    top: 21px;
}

.team-1 .formation-5-4-1 .a7 {
    left: 189px;
    top: 87px;
}

.team-1 .formation-5-4-1 .a8 {
    left: 193px;
    top: 41px;
}

.team-1 .formation-5-4-1 .a9 {
    left: 239px;
    bottom: 116px;
}

.team-1 .formation-5-4-1 .a10 {
    right: 194px;
    top: 41px;
}

.team-1 .formation-5-4-1 .a11 {
    right: 146px;
    bottom: 145px;
}
/* final posições */


/* posições max 480 - posição esquerda do campo */
.team-2 .formation-3-4-3 .a1 {
    right: 82px;
    top: 60px;
}

.team-2 .formation-3-4-3 .a2 {
    right: 122px;
    bottom: 95px;
}

.team-2 .formation-3-4-3 .a3 {
    right: 134px;
    top: 60px;
}

.team-2 .formation-3-4-3 .a4 {
    right: 144px;
    top: 20px;
}

.team-2 .formation-3-4-3 .a5 {
    left: 225px;
    top: 37px;
}

.team-2 .formation-3-4-3 .a6 {
    left: 225px;
    bottom: 115px;
}

.team-2 .formation-3-4-3 .a7 {
    right: 254px;
    bottom: 79px;
}

.team-2 .formation-3-4-3 .a8 {
    right: 245px;
    top: 23px;
}

.team-2 .formation-3-4-3 .a9 {
    right: 326px;
    bottom: 88px;
}

.team-2 .formation-3-4-3 .a10 {
    left: 130px;
    top: 22px;
}

.team-2 .formation-3-4-3 .a11 {
    left: 99px;
    bottom: 143px;
}

.team-2 .formation-3-5-2 .a1 {
    right: 83px;
    top: 60px;
}

.team-2 .formation-3-5-2 .a2 {
    right: 115px;
    bottom: 95px;
}

.team-2 .formation-3-5-2 .a3 {
    right: 127px;
    top: 60px;
}

.team-2 .formation-3-5-2 .a4 {
    right: 137px;
    top: 20px;
}

.team-2 .formation-3-5-2 .a5 {
    right: 190px;
    bottom: 139px;
}

.team-2 .formation-3-5-2 .a6 {
    right: 225px;
    bottom: 106px;
}

.team-2 .formation-3-5-2 .a7 {
    right: 224px;
    top: 35px;
}

.team-2 .formation-3-5-2 .a8 {
    right: 283px;
    bottom: 75px;
}

.team-2 .formation-3-5-2 .a9 {
    right: 268px;
    top: 18px;
}

.team-2 .formation-3-5-2 .a10 {
    left: 94px;
    bottom: 107px;
}

.team-2 .formation-3-5-2 .a11 {
    left: 116px;
    bottom: 163px;
}

.team-2 .formation-4-3-3 .a1 {
    right: 82px;
    top: 60px;
}

.team-2 .formation-4-3-3 .a2 {
    right: 160px;
    bottom: 72px;
}

.team-2 .formation-4-3-3 .a3 {
    right: 118px;
    top: 93px;
}

.team-2 .formation-4-3-3 .a4 {
    right: 133px;
    top: 40px;
}

.team-2 .formation-4-3-3 .a5 {
    right: 172px;
    top: 14px;
}

.team-2 .formation-4-3-3 .a6 {
    right: 265px;
    bottom: 83px;
}

.team-2 .formation-4-3-3 .a7 {
    right: 224px;
    top: 56px;
}

.team-2 .formation-4-3-3 .a8 {
    right: 257px;
    top: 23px;
}

.team-2 .formation-4-3-3 .a9 {
    right: 321px;
    bottom: 88px;
}

.team-2 .formation-4-3-3 .a10 {
    left: 138px;
    top: 20px;
}

.team-2 .formation-4-3-3 .a11 {
    left: 86px;
    bottom: 144px;
}

.team-2 .formation-4-4-2 .a1 {
    right: 73px;
    top: 60px;
}

.team-2 .formation-4-4-2 .a2 {
    right: 116px;
    bottom: 72px;
}

.team-2 .formation-4-4-2 .a3 {
    right: 127px;
    top: 93px;
}

.team-2 .formation-4-4-2 .a4 {
    right: 139px;
    top: 52px;
}

.team-2 .formation-4-4-2 .a5 {
    right: 150px;
    top: 14px;
}

.team-2 .formation-4-4-2 .a6 {
    right: 200px;
    bottom: 114px;
}

.team-2 .formation-4-4-2 .a7 {
    right: 200px;
    top: 42px;
}

.team-2 .formation-4-4-2 .a8 {
    right: 243px;
    bottom: 114px;
}

.team-2 .formation-4-4-2 .a9 {
    right: 240px;
    top: 43px;
}

.team-2 .formation-4-4-2 .a10 {
    left: 128px;
    top: 42px;
}

.team-2 .formation-4-4-2 .a11 {
    left: 116px;
    bottom: 123px;
}

.team-2 .formation-4-5-1 .a1 {
    right: 81px;
    top: 60px;
}

.team-2 .formation-4-5-1 .a2 {
    right: 140px;
    bottom: 72px;
}

.team-2 .formation-4-5-1 .a3 {
    right: 119px;
    top: 93px;
}

.team-2 .formation-4-5-1 .a4 {
    right: 129px;
    top: 52px;
}

.team-2 .formation-4-5-1 .a5 {
    right: 158px;
    top: 14px;
}

.team-2 .formation-4-5-1 .a6 {
    right: 287px;
    bottom: 83px;
}

.team-2 .formation-4-5-1 .a7 {
    right: 225px;
    bottom: 115px;
}

.team-2 .formation-4-5-1 .a8 {
    right: 190px;
    bottom: 142px;
}

.team-2 .formation-4-5-1 .a9 {
    right: 224px;
    top: 37px;
}

.team-2 .formation-4-5-1 .a10 {
    left: 155px;
    top: 20px;
}

.team-2 .formation-4-5-1 .a11 {
    left: 96px;
    bottom: 145px;
}

.team-2 .formation-5-4-1 .a1 {
    right: 81px;
    top: 60px;
}

.team-2 .formation-5-4-1 .a2 {
    right: 140px;
    bottom: 72px;
}

.team-2 .formation-5-4-1 .a3 {
    right: 122px;
    bottom: 107px;
}

.team-2 .formation-5-4-1 .a4 {
    right: 114px;
    top: 59px;
}

.team-2 .formation-5-4-1 .a5 {
    right: 140px;
    top: 39px;
}

.team-2 .formation-5-4-1 .a6 {
    right: 165px;
    top: 21px;
}

.team-2 .formation-5-4-1 .a7 {
    right: 192px;
    top: 85px;
}

.team-2 .formation-5-4-1 .a8 {
    right: 193px;
    top: 43px;
}

.team-2 .formation-5-4-1 .a9 {
    right: 243px;
    bottom: 115px;
}

.team-2 .formation-5-4-1 .a10 {
    left: 189px;
    top: 41px;
}

.team-2 .formation-5-4-1 .a11 {
    left: 136px;
    bottom: 145px;
}
/* final posições */


/* escalação */
.aovivo .list-escalation {
    float: left;
    width: 100%;
    text-align: center;
}

.aovivo .list-escalation li {
    float: left;
    width: 100%;
    font-size: 17px;
    font-family: arial;
    color: #333;
    letter-spacing: 0;
    line-height: normal;
    padding: 5px 0;
    margin: 0;
}

.aovivo .list-escalation li.item-escalation.backup {
    color: #7f7f7f!important;
}

.aovivo .list-escalation li.coach {
    color: gray;
    border-bottom: 2px solid gray;
    padding: 0;
}

.aovivo .list-escalation li.coach p {
    float: left;
    width: 100%;
    max-width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

.aovivo .list-escalation li.coach p strong {
    padding: 0 5px 0 0;
}

.aovivo .list-escalation li p {
    float: left;
    padding: 2px 0 0;
    margin: 0;
    line-height: normal;
}

.aovivo .list-escalation li p strong {
    display: inline;
    padding: 0 5px 0 12px;
}

.aovivo .container-right {
    float: right;
    min-height: 24px;
    margin-right: 15px;
    position: relative;
}

.aovivo .container-right i {
    float: left;
    margin-left: 8px;
}

.aovivo .container-right .icon-substitution-out,.icon-substitution-in {
    margin-top: 7px;
}

.aovivo .team-position {
    float: left;
    width: 100%;
    padding: 13px 0;
    background: #fafafa;
}

.aovivo .team-position .team-position-item {
    float: left;
    width: 49%;
    text-align: center;
    font-size: 18px;
    color: #cfcfcf;
    font-weight: 700;
    font-family: arial;
}

.aovivo .team-position .team-position-item a {
    color: #cfcfcf;
    -webkit-tap-highlight-color: transparent;
}

.aovivo .team-position .team-position-item.active a {
    color: #ffae2b;
}
/* final escalação */


/* legenda */
.legend {
    float: left;
    width: 100%;
    padding: 45px 0 0;
    position: relative;
}

.legend hr {
    float: left;
    width: 100%;
    border: 1px solid gray;
    position: absolute;
    top: 110px;
}

.legend h3 {
    float: left;
    width: 100%;
    text-align: center;
    font-family: arial;
    color: #666;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.legend .legend-list {
    float: left;
    width: 100%;
    margin: 15px 0 0!important;
    font-family: arial;
    text-align: center;
}

.legend-list .legend-item {
    float: left;
    width: 16%;
    font-family: arial;
    color: #666;
    font-size: 17px;
    letter-spacing: 0;
    border: 0;
    padding: 0;
    margin: 0;
}

.legend-list .legend-item span {
    float: left;
    width: 100%;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.legend-list .legend-item p {
    float: left;
    width: 100%;
    height: 38px;
    font-size: 13px;
    line-height: normal;
    margin: 0;
    padding: 5px 0 0;
}

.legend-list .legend-item .icon-substitution-in,.legend-list .legend-item .icon-substitution-out {
    display: table-cell;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
}

.smartphone-line {
    display: none;
}
/* final legenda */



/* eventos */
.aovivo .events {
    float: left;
    width: 100%;
    display: none;
}

.aovivo .events h2 {
    display: none;
}

.aovivo .events .list-events {
    float: left;
    width: 100%;
    margin: 0 0 15px!important;
}

.events .list-events .item-events {
    float: left;
    width: 100%;
    line-height: normal;
    margin: 0;
    padding: 10px 0;
    letter-spacing: 0;
    font-size: 17px;
    position: relative;
}

.events .list-events .item-events:last-child {
    border: none;
}

.list-events .item-events.title {
    margin: 4px 0;
    color: #ffae2b;
    font-size: 18px;
    font-family: arial;
    font-weight: 700;
    padding: 10px 0;
    background: #fafafa;
    border: none;
    text-indent: 10px;
}

.events .list-events i {
    float: left;
    height: 26px;
    margin: -16px 10px 0;
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
}

.events .list-events p {
    float: left;
    width: auto;
    margin: 0;
    padding: 0 0 0 47px;
    font-family: arial;
    color: #666;
}

.events .list-events p strong {
    padding-right: 6px;
}

.events .list-events .time {
    float: left;
    width: 50%;
    font-family: arial;
    text-align: left;
    font-size: 17px;
    font-weight: 700;
    color: #666;
    padding-top: 10px;
}

.events .list-events .time p {
    float: left;
    width: 100%;
}

.events .list-events .player {
    float: right;
    width: 50%;
}

.events .list-events .player-item {
    float: left;
    width: 100%;
    font-family: arial;
    position: relative;
}

.events .list-events .player-item p {
    float: left;
    width: 100%;
    text-align: right;
    margin-left: -45px;
}

.events .list-events .player-item i {
    float: right;
    height: auto;
    margin: -5px 0 0;
    vertical-align: middle;
    display: table-cell;
    position: absolute;
    top: 50%;
    right: 5px;
}
/* final eventos */


/* coluna eventos */
.column-events {
    float: right;
    width: 30%;
    display: none;
    position: relative;
}

.column-events h2 {
    float: left;
    width: 100%;
    color: #02b7ee;
    font-size: 2.5em;
    border-bottom: 3px solid #02b7ee;
    display: block!important;
}
/* final coluna eventos */


/* aovivo update */
.aovivo-update #tooltip-aovivo-update {
    width: 205px;
    position: absolute;
    top: 55px;
    left: 50%;
    margin-left: -103px;
    right: auto;
}


/* final aovivo update */
/* ################### final Ao vivo na Copa################ */


/* Max 480 - iPhone Portrait - One Column  */
@media handheld,only screen and (max-width:480px),only screen and (max-device-width:480px) and (orientation:portrait) {
        .scoreboard .list-football {
        padding: 27px 0 0!important;
    }

    .aovivo .confrontation h2{
        font-size: 1.6em;
    }

    .number-scoreboard .number-item {
        width: 20px;
        font-size: 38px;
    }

    .number-scoreboard .number-item strong {
        width: 20px;
    }

    .number-scoreboard .versus {
        width: 40px;
    }
    .number-scoreboard .versus strong {
        width: 40px;
        height: 28px;
        font-size: 35px;
        line-height: 32px;
        line-height: 40px;
    }
    .list-football-item .flag img {
        max-width: 65px;
    }

    .number-scoreboard .transmission {
        font-size: 12px;
    }

    .list-football-item .team-name {
        font-size: 16px;
    }
    .aovivo .menu .list-menu li {
        font-size: 16px;
    }
    .list-football .list-football-item.number-scoreboard {
        margin: 8px 0 0;
    }
    .aovivo .escalation .graphic-position {
        max-width: 295px;
        height: 175px;
    }
    .aovivo .graphic-position .uniform {
        width: 18px;
        height: 22px;
        line-height: 22px;
        font-size: 9px;
    }
    .team-1 .formation-3-4-3 .a1 {
        left: 50px;
        top: 50px;
    }

    .team-1 .formation-3-4-3 .a2 {
        left: 70px;
        bottom: 76px;
    }

    .team-1 .formation-3-4-3 .a3 {
        left: 77px;
        top: 50px;
    }

    .team-1 .formation-3-4-3 .a4 {
        left: 84px;
        top: 26px;
    }

    .team-1 .formation-3-4-3 .a5 {
        left: 136px;
        bottom: 87px;
    }

    .team-1 .formation-3-4-3 .a6 {
        left: 136px;
        top: 37px;
    }

    .team-1 .formation-3-4-3 .a7 {
        left: 167px;
        bottom: 73px;
    }

    .team-1 .formation-3-4-3 .a8 {
        left: 162px;
        top: 24px;
    }

    .team-1 .formation-3-4-3 .a9 {
        left: 201px;
        bottom: 70px;
    }

    .team-1 .formation-3-4-3 .a10 {
        right: 91px;
        top: 24px;
    }

    .team-1 .formation-3-4-3 .a11 {
        right: 64px;
        bottom: 107px;
    }

    .team-1 .formation-3-5-2 .a1 {
        left: 50px;
        top: 49px;
    }

    .team-1 .formation-3-5-2 .a2 {
        left: 69px;
        bottom: 76px;
    }

    .team-1 .formation-3-5-2 .a3 {
        left: 77px;
        top: 51px;
    }

    .team-1 .formation-3-5-2 .a4 {
        left: 85px;
        top: 30px;
    }

    .team-1 .formation-3-5-2 .a5 {
        left: 115px;
        bottom: 102px;
    }

    .team-1 .formation-3-5-2 .a6 {
        left: 138px;
        bottom: 85px;
    }

    .team-1 .formation-3-5-2 .a7 {
        left: 136px;
        top: 35px;
    }

    .team-1 .formation-3-5-2 .a8 {
        left: 175px;
        bottom: 75px;
    }

    .team-1 .formation-3-5-2 .a9 {
        left: 166px;
        top: 27px;
    }

    .team-1 .formation-3-5-2 .a10 {
        right: 61px;
        bottom: 85px;
    }

    .team-1 .formation-3-5-2 .a11 {
        right: 70px;
        bottom: 117px;
    }

    .team-1 .formation-4-3-3 .a1 {
        left: 50px;
        top: 50px;
    }

    .team-1 .formation-4-3-3 .a2 {
        left: 107px;
        bottom: 65px;
    }

    .team-1 .formation-4-3-3 .a3 {
        left: 72px;
        top: 68px;
    }

    .team-1 .formation-4-3-3 .a4 {
        left: 80px;
        top: 40px;
    }

    .team-1 .formation-4-3-3 .a5 {
        left: 110px;
        top: 27px;
    }

    .team-1 .formation-4-3-3 .a6 {
        left: 158px;
        bottom: 72px;
    }

    .team-1 .formation-4-3-3 .a7 {
        left: 133px;
        top: 49px;
    }

    .team-1 .formation-4-3-3 .a8 {
        left: 155px;
        top: 27px;
    }

    .team-1 .formation-4-3-3 .a9 {
        left: 190px;
        bottom: 65px;
    }

    .team-1 .formation-4-3-3 .a10 {
        right: 94px;
        top: 24px;
    }

    .team-1 .formation-4-3-3 .a11 {
        right: 58px;
        bottom: 103px;
    }

    .team-1 .formation-4-4-2 .a1 {
        left: 47px;
        top: 49px;
    }

    .team-1 .formation-4-4-2 .a2 {
        left: 90px;
        bottom: 59px;
    }

    .team-1 .formation-4-4-2 .a3 {
        left: 93px;
        top: 70px;
    }

    .team-1 .formation-4-4-2 .a4 {
        left: 95px;
        top: 46px;
    }

    .team-1 .formation-4-4-2 .a5 {
        left: 96px;
        top: 26px;
    }

    .team-1 .formation-4-4-2 .a6 {
        left: 123px;
        bottom: 87px;
    }

    .team-1 .formation-4-4-2 .a7 {
        left: 121px;
        top: 37px;
    }

    .team-1 .formation-4-4-2 .a8 {
        left: 148px;
        bottom: 115px;
    }

    .team-1 .formation-4-4-2 .a9 {
        left: 152px;
        top: 65px;
    }

    .team-1 .formation-4-4-2 .a10 {
        right: 86px;
        top: 38px;
    }

    .team-1 .formation-4-4-2 .a11 {
        right: 82px;
        bottom: 90px;
    }

    .team-1 .formation-4-5-1 .a1 {
        left: 49px;
        top: 50px;
    }

    .team-1 .formation-4-5-1 .a2 {
        left: 105px;
        bottom: 63px;
    }

    .team-1 .formation-4-5-1 .a3 {
        left: 72px;
        top: 73px;
    }

    .team-1 .formation-4-5-1 .a4 {
        left: 80px;
        top: 46px;
    }

    .team-1 .formation-4-5-1 .a5 {
        left: 109px;
        top: 22px;
    }

    .team-1 .formation-4-5-1 .a6 {
        left: 164px;
        bottom: 72px;
    }

    .team-1 .formation-4-5-1 .a7 {
        left: 141px;
        bottom: 89px;
    }

    .team-1 .formation-4-5-1 .a8 {
        left: 117px;
        bottom: 106px;
    }

    .team-1 .formation-4-5-1 .a9 {
        left: 141px;
        top: 34px;
    }

    .team-1 .formation-4-5-1 .a10 {
        right: 115px;
        top: 25px;
    }

    .team-1 .formation-4-5-1 .a11 {
        right: 64px;
        bottom: 104px;
    }

    .team-1 .formation-5-4-1 .a1 {
        left: 48px;
        top: 48px;
    }

    .team-1 .formation-5-4-1 .a2 {
        left: 104px;
        bottom: 61px;
    }

    .team-1 .formation-5-4-1 .a3 {
        left: 76px;
        top: 76px;
    }

    .team-1 .formation-5-4-1 .a4 {
        left: 76px;
        top: 47px;
    }

    .team-1 .formation-5-4-1 .a5 {
        left: 89px;
        top: 31px;
    }

    .team-1 .formation-5-4-1 .a6 {
        left: 109px;
        top: 21px;
    }

    .team-1 .formation-5-4-1 .a7 {
        left: 117px;
        top: 67px;
    }

    .team-1 .formation-5-4-1 .a8 {
        left: 118px;
        top: 41px;
    }

    .team-1 .formation-5-4-1 .a9 {
        left: 158px;
        top: 66px;
    }

    .team-1 .formation-5-4-1 .a10 {
        right: 121px;
        top: 41px;
    }

    .team-1 .formation-5-4-1 .a11 {
        right: 83px;
        bottom: 103px;
    }

    .team-2 .formation-3-4-3 .a1 {
        right: 50px;
        top: 50px;
    }

    .team-2 .formation-3-4-3 .a2 {
        right: 70px;
        bottom: 76px;
    }

    .team-2 .formation-3-4-3 .a3 {
        right: 77px;
        top: 50px;
    }

    .team-2 .formation-3-4-3 .a4 {
        right: 84px;
        top: 26px;
    }

    .team-2 .formation-3-4-3 .a5 {
        left: 136px;
        bottom: 87px;
    }

    .team-2 .formation-3-4-3 .a6 {
        left: 136px;
        bottom: 84px;
    }

    .team-2 .formation-3-4-3 .a7 {
        right: 167px;
        bottom: 73px;
    }

    .team-2 .formation-3-4-3 .a8 {
        right: 162px;
        top: 24px;
    }

    .team-2 .formation-3-4-3 .a9 {
        right: 201px;
        bottom: 70px;
    }

    .team-2 .formation-3-4-3 .a10 {
        left: 91px;
        top: 24px;
    }

    .team-2 .formation-3-4-3 .a11 {
        left: 64px;
        bottom: 107px;
    }

    .team-2 .formation-3-5-2 .a1 {
        right: 50px;
        top: 49px;
    }

    .team-2 .formation-3-5-2 .a2 {
        right: 69px;
        bottom: 76px;
    }

    .team-2 .formation-3-5-2 .a3 {
        right: 77px;
        top: 51px;
    }

    .team-2 .formation-3-5-2 .a4 {
        right: 85px;
        top: 30px;
    }

    .team-2 .formation-3-5-2 .a5 {
        right: 115px;
        bottom: 102px;
    }

    .team-2 .formation-3-5-2 .a6 {
        right: 138px;
        bottom: 85px;
    }

    .team-2 .formation-3-5-2 .a7 {
        right: 136px;
        top: 35px;
    }

    .team-2 .formation-3-5-2 .a8 {
        right: 175px;
        bottom: 75px;
    }

    .team-2 .formation-3-5-2 .a9 {
        right: 166px;
        top: 27px;
    }

    .team-2 .formation-3-5-2 .a10 {
        left: 61px;
        bottom: 85px;
    }

    .team-2 .formation-3-5-2 .a11 {
        left: 70px;
        bottom: 117px;
    }

    .team-2 .formation-4-3-3 .a1 {
        right: 50px;
        top: 50px;
    }

    .team-2 .formation-4-3-3 .a2 {
        right: 107px;
        bottom: 65px;
    }

    .team-2 .formation-4-3-3 .a3 {
        right: 72px;
        top: 68px;
    }

    .team-2 .formation-4-3-3 .a4 {
        right: 80px;
        top: 40px;
    }

    .team-2 .formation-4-3-3 .a5 {
        right: 110px;
        top: 27px;
    }

    .team-2 .formation-4-3-3 .a6 {
        right: 158px;
        bottom: 72px;
    }

    .team-2 .formation-4-3-3 .a7 {
        right: 133px;
        top: 49px;
    }

    .team-2 .formation-4-3-3 .a8 {
        right: 155px;
        top: 27px;
    }

    .team-2 .formation-4-3-3 .a9 {
        right: 190px;
        bottom: 65px;
    }

    .team-2 .formation-4-3-3 .a10 {
        left: 94px;
        top: 24px;
    }

    .team-2 .formation-4-3-3 .a11 {
        left: 58px;
        bottom: 103px;
    }

    .team-2 .formation-4-4-2 .a1 {
        right: 47px;
        top: 49px;
    }

    .team-2 .formation-4-4-2 .a2 {
        right: 79px;
        bottom: 59px;
    }

    .team-2 .formation-4-4-2 .a3 {
        right: 85px;
        top: 70px;
    }

    .team-2 .formation-4-4-2 .a4 {
        right: 91px;
        top: 46px;
    }

    .team-2 .formation-4-4-2 .a5 {
        right: 96px;
        top: 26px;
    }

    .team-2 .formation-4-4-2 .a6 {
        right: 123px;
        bottom: 87px;
    }

    .team-2 .formation-4-4-2 .a7 {
        right: 126px;
        top: 37px;
    }

    .team-2 .formation-4-4-2 .a8 {
        right: 154px;
        bottom: 115px;
    }

    .team-2 .formation-4-4-2 .a9 {
        right: 156px;
        top: 65px;
    }

    .team-2 .formation-4-4-2 .a10 {
        left: 82px;
        top: 38px;
    }

    .team-2 .formation-4-4-2 .a11 {
        left: 76px;
        bottom: 90px;
    }

    .team-2 .formation-4-5-1 .a1 {
        right: 49px;
        top: 50px;
    }

    .team-2 .formation-4-5-1 .a2 {
        right: 105px;
        bottom: 63px;
    }

    .team-2 .formation-4-5-1 .a3 {
        right: 72px;
        top: 73px;
    }

    .team-2 .formation-4-5-1 .a4 {
        right: 80px;
        top: 46px;
    }

    .team-2 .formation-4-5-1 .a5 {
        right: 109px;
        top: 22px;
    }

    .team-2 .formation-4-5-1 .a6 {
        right: 164px;
        bottom: 72px;
    }

    .team-2 .formation-4-5-1 .a7 {
        right: 141px;
        bottom: 89px;
    }

    .team-2 .formation-4-5-1 .a8 {
        right: 117px;
        bottom: 106px;
    }

    .team-2 .formation-4-5-1 .a9 {
        right: 141px;
        top: 34px;
    }

    .team-2 .formation-4-5-1 .a10 {
        left: 115px;
        top: 25px;
    }

    .team-2 .formation-4-5-1 .a11 {
        left: 64px;
        bottom: 104px;
    }

    .team-2 .formation-5-4-1 .a1 {
        right: 48px;
        top: 48px;
    }

    .team-2 .formation-5-4-1 .a2 {
        right: 104px;
        bottom: 61px;
    }

    .team-2 .formation-5-4-1 .a3 {
        right: 76px;
        top: 76px;
    }

    .team-2 .formation-5-4-1 .a4 {
        right: 76px;
        top: 47px;
    }

    .team-2 .formation-5-4-1 .a5 {
        right: 89px;
        top: 31px;
    }

    .team-2 .formation-5-4-1 .a6 {
        right: 113px;
        top: 21px;
    }

    .team-2 .formation-5-4-1 .a7 {
        right: 123px;
        top: 67px;
    }

    .team-2 .formation-5-4-1 .a8 {
        right: 126px;
        top: 41px;
    }

    .team-2 .formation-5-4-1 .a9 {
        right: 158px;
        top: 66px;
    }

    .team-2 .formation-5-4-1 .a10 {
        left: 121px;
        top: 41px;
    }

    .team-2 .formation-5-4-1 .a11 {
        left: 83px;
        bottom: 103px;
    }

    .legend-list .legend-item {
        display: inline-block;
        float: none;
        min-width: 93px;
    }

    .smartphone-line {
        display: block;
        top: 187px!important;
    }

    .aovivo .confrontation h2 {
        font-size: 30px;
    }

}
/* Max 560 - One Column - Simple Header */
@media only screen and (max-width:560px) {
    .scoreboard .list-football {
        padding: 27px 0 0!important;
    }

    .number-scoreboard .number-item {
        width: 20px;
        font-size: 38px;
    }

    .number-scoreboard .number-item strong {
        width: 20px;
    }

    .number-scoreboard .versus {
        width: 40px;
    }

    .number-scoreboard .versus strong {
        width: 40px;
        height: 28px;
        font-size: 35px;
        line-height: 32px;
        line-height: 40px;
    }

}
@media only screen and (min-width :1024px) and (orientation:landscape) {
    .aovivo .menu .list-menu li.noipad {
        display: none;
    }

    .aovivo .menu .list-menu li{
        width: 50%;
    }
    .aovivo .menu .list-menu li {
        font-size: 18px;
    }
    .column-events {
        display: block;
    }
    .column-events .events {
        display: block;
    }
}